@import "~baseVariables";
@import "~masterVariables";
.base_form_form .cols .col .field > *:not(.fieldIndependent).templates_ttgShop_orders_fields_address,
.templates_ttgShop_orders_fields_address {
	width: 100% !important;

	& > ol {
		list-style-type: none;
		padding-inline-start: 1.5em;
		background-color: #E7E6E4;
		border-radius: .75em;
		padding: 1em;
		margin: .5em 0;
		border: 1px solid #AAAAAA;

		& > li:first-child {
			font-weight: bold;
		}
	}
}