@import "~baseVariables";
@import "~masterVariables";
.templates_bulk_layoutTrees {

  &.splitScreenTriple {
    & > section.splitScreenLeft {
      & > section.filter {
        & > .templates_treesManagement_filter.isHorizontal {
          width: 100%;
          padding-right: 20px;
        }
      }
    }
  }

  &.squared {
    & > section.filter {
      & > .templates_treesManagement_filter.isHorizontal {
        width: 70%;
      }
    }
  }
}