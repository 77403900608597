@import "~baseVariables";
@import "~masterVariables";
.templates_mediaManagement_file_nrOfElements {
	margin-top: auto;
	box-sizing: border-box;

	& > table {
		width: 100%;
		font-size: .875em;
		font-style: italic;

		& > tbody {
			& > tr {
				& > td {
					text-align: left;
					&:last-of-type {
						text-align: right;
					}
				}
			}
		}
	}
}