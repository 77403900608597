@import "~baseVariables";
@import "~masterVariables";
.templates_artCrm_activity_table {
	height: 100%;
	overflow: auto;

	& > table {

		& > thead,
		& > tbody {
			& > tr {
				& > td {
					&:first-child {
						border-left: none;
					}
				}
			}
		}

		& > thead {
			& > tr {
				& > td {
					border-top: none;
				}
			}
		}

		& > tbody {
			max-height: 100%;
			overflow: auto;
		}

	}
}