@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_mediaManagement_actionsForm.templates_mediaManagement_filterForm {
	flex: 1 1 30%;

	button {
		margin-left: 0;
	}

	.cols .col .field {
		&:last-child {
			margin-bottom: 0;
		}
	}
}