@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_table_table {
	font-size: 13px;

	& > table > thead > tr > td,
	& > table > tbody > tr > td {
		font-weight: normal;

		&.headOf_mailingID,
		&.fieldOf_mailingID {
			//font-weight: bold;
			padding-top: 0;
			border-bottom: 1px solid #DDDDDD;
		}
		&.headOf_subject,
		&.fieldOf_subject {
			font-weight: bold;
		}
		&.headOf_sendStatus,
		&.fieldOf_sendStatus {
			padding-right: 0;
		}
		&.headOf_statsRecipients,
		&.fieldOf_statsRecipients {
			padding-left: 0;
		}
		&.headOf_newsletter,
		&.fieldOf_newsletter {
			font-style: italic;
		}

		&:last-child {
			border-right: none !important;
		}

		&.base_tableSimple_colActions {
			border-right: 1px solid #DDDDDD !important;
			border-bottom: 1px solid #DDDDDD;
			padding: 2px 16px;

			& > .buttons {
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				padding-top: 1px;

				& > .left {
					width: 115px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-end;
				}

				& > .right {
					display: flex;
					flex: 1 1 auto;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
				}

				&,
				& > * {
					& > button {
						margin: 0 0 0 25px !important;
						flex: 0 1 auto;
						background-color: @primaryColor;
						border-color: transparent;
						color: #FFFFFF;
						display: inline-flex;
						justify-content: flex-start;
						align-items: center;

						&:hover,
						&:focus,
						&:active {
							background-color: #9c5000;
							border-color: @primaryColor;
						}

						& > i {
							margin-right: 10px;
						}

						&.symbolOnly {
							padding: 5px 7px;
							margin: 0 !important;

							& > i {
								margin-right: 0;
							}

							&,
							&.negative,
							&.positive {
								color: #555555;
								border-color: transparent;
								background-color: transparent;

								&:hover,
								&:focus,
								&:active {
									color: @primaryColor;
									border-color: @primaryColor;
									background-color: transparent;
								}
							}
						}
					}
				}
			}
		}
	}

	& > table > tbody.isChildMailing {
		font-size: 11px;
		//background-color: #FFFFFF !important;

		& > tr {

			&:first-child > td {
				padding-top: 6px;
				padding-bottom: 1px;
			}
			&:last-child > td {
				padding-top: 1px;
				padding-bottom: 6px;
			}


			&:first-child > td {
				&:first-child {
					//padding-left: 32px;
				}

				&.base_tableSimple_colActions {
					padding: 2px 16px;
				}
			}

			td.fieldOf_subject,
			td.fieldOf_newsletter {
				color: #AAAAAA;
			}

			td.fieldOf_sendStatusChart .templates_newsletter_mailings_table_fields_sendStatusChart {
				height: 12px;
			}
		}
	}
}