@import "~baseVariables";
@import "~masterVariables";
.templates_events_table_table {
  .headOf_langCode,
  .headOf_title,
  .fieldOf_title,
  .headOf_subTitle,
  .fieldOf_subTitle,
  .headOf_path,
  .fieldOf_path {
    font-weight: normal;
    font-style: italic;

    label {
      padding-right: 2px; // needed for overflow: hidden on font-style: italic
    }
  }

  .headOf_langCode {
    & > .headContainer {
      justify-content: flex-end;
    }
  }
  .fieldOf_langCode {
    text-align: right;
  }

  .base_tableSimple_colActions {
    & > .buttons {
      margin: 3px 0;
    }
  }

  .flag-icon {
    position: unset;
  }
}
