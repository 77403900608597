@import "~baseVariables";
@import "~masterVariables";
@border_color_row: 	#9A9A9A;
@fg_color_head: 		#9A9A9A;
@fg_color_content: 	#333333;

.templates_artCrm_contactList_contactList {
	display: flex;
	flex-direction: column;
	font-size: .875em;

	& > section {
		display: flex;
		flex-direction: row;

		&.head {
			align-items: center;

			& > h2 {
				font-size: 1.325rem;
				padding: .5rem 0;
				margin: 0;
				text-transform: uppercase;
				color: #666;
			}

			& > .buttons {
				margin-left: auto;
			}
		}

		&.content {
			flex-direction: column;
			border-top: 1px solid @border_color_row;
			padding-bottom: 2em;

			& > .listRow {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				justify-content: flex-start;
				border-bottom: 1px solid @border_color_row;
				padding: 1em 0 .25em 1em;

				&:last-child {
						border-bottom: none;
				}

				& > .listCols {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;

					& > .listCol {
						display: flex;
						flex-direction: column;
						flex: 0 1 auto;
						padding: 0 2em .75em 0;
						max-width: 100%;

						&:last-child {
							padding-right: 0;
						}

						& > .head {
							text-transform: uppercase;
							color: @fg_color_head;
							padding-bottom: .25em;
						}

						& > .content {
							color: @fg_color_content;
							white-space: pre-wrap;

							& > a,
							& > a:hover,
							& > a:active,
							& > a:focus {
								color: #333333;
							}
						}
					}
				}

				& > .buttons {
					width: auto;
					margin-left: auto;
					padding-top: 2px;
					display: flex;
					flex-direction: row;
					justify-content: flex-end;

					& > button {
						margin: 0 0 0 .5em;
						width: auto;

						& > i {
							margin: 0;
						}
					}
				}

				&.emptyRow {
					justify-content: center;
					margin-top: 1em;
					font-size: 1.125rem;
					color: #333333;
					padding: 1em 0;
				}
			}
		}
	}
}