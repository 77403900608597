@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_edit_properties_properties {
	padding: 2em;
	background-color: #FFFFFF;
	//height: 100%;

	//label {
	//	width: 20% !important;
	//}
	//
	//.field > *:not(.fieldIndependent) {
	//	width: 80% !important;
	//}
}