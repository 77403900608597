//@import './sources/font-awesome-6.2.0/css/brands.css';
//@import './sources/font-awesome-6.2.0/css/regular.css';
//@import './sources/font-awesome-6.2.0/css/solid.css';
//@import (reference, less) './sources/font-awesome-6.2.0/css/all.css';
@import '../../node_modules/frontend-controller/styles/icons/vendor/font-awesome-6.2.0/css/brands.css';
@import '../../node_modules/frontend-controller/styles/icons/vendor/font-awesome-6.2.0/css/regular.css';
@import '../../node_modules/frontend-controller/styles/icons/vendor/font-awesome-6.2.0/css/solid.css';
@import (reference, less) '../../node_modules/frontend-controller/styles/icons/vendor/font-awesome-6.2.0/css/all.css';


/////////////////
/// BASE
/////////////////


/////////////////
/// COMMON
/////////////////
.fi.fi-upload										:extend(.fa-upload all){}
.fi.fi-upload-tagged						:extend(.fa-share-alt all){}
.fi.fi-signIn-submit						:extend(.fa-sign-in-alt all){}
.fi.fi-usd											:extend(.fa-dollar-sign all){}
.fi.fi-eye											:extend(.fa-eye all){}
.fi.fi-cut											:extend(.fas all, .fa-cut all){ font-weight: 900 !important; }
.fi.fi-paste										:extend(.fas all, .fa-paste all){ font-weight: 900 !important; }
.fi.fi-flag									:extend(.far all, .fa-flag all){}
.fi.fi-reset									:extend(.fas all, .fa-ban all){ font-weight: 900 !important; }
.fi.fi-calendar-daily				:extend(.fas all, .fa-calendar-day all){}
.fi.fi-calendar-monthly				:extend(.fas all, .fa-calendar all){}


/////////////////
/// WEBSITE
/////////////////
.fi.fi-website 									:extend(.fa-globe all){}
.fi.fi-showProperties-route			:extend(.fa-cog all){ font-weight: 900 !important; }
.fi.fi-routeTree								:extend(.fa-sitemap all){ font-weight: 900 !important; }
.fi.fi-layouts 									:extend(.far all, .fa-window-restore all){ font-weight: 400 !important; }
.fi.fi-components 							:extend(.fa-cubes all){ font-weight: 900 !important; }
.fi.fi-atoms 										:extend(.fa-leaf all){ font-weight: 900 !important; }
.fi.fi-tagScopes 								:extend(.fa-hashtag all){ font-weight: 900 !important; }
.fi.fi-validators 							:extend(.fa-bolt all){ font-weight: 900 !important; }
.fi.fi-validator 								:extend(.fa-bolt all){ font-weight: 900 !important; }
.fi.fi-component 								:extend(.far all, .fa-file-alt all){}
.fi.fi-shadowComponent					:extend(.fa-file-alt all){}
.fi.fi-form 										:extend(.far all, .fa-list-alt all){}
.fi.fi-layout										:extend(.far all, .fa-map all){}
.fi.fi-atom 										:extend(.fa-leaf all){}
.fi.fi-route 										:extend(.far all, .fa-file-alt all){}
.fi.fi-routeFallback 						:extend(.far all, .fa-file-alt all){}
.fi.fi-open-routes							:extend(.fas all, .fa-external-link-alt all){}
.fi.fi-homeRoute 								:extend(.fa-home all){}
.fi.fi-banner 									:extend(.far all, .fa-image all){ font-weight: 400 !important; }
.fi.fi-collapse									:extend(.fa-caret-down all){}
.fi.fi-collapsed								:extend(.fa-caret-right all){}
.fi.fi-caret-up								    :extend(.fa-caret-up all){}
.fi.fi-caret-down								:extend(.fa-caret-down all){}
.fi.fi-caret-left								:extend(.fa-caret-left all){}
.fi.fi-caret-right								:extend(.fa-caret-right all){}
.fi.fi-art-download-stats				:extend(.fa-chart-area all){ font-weight: 900 !important; }
.fi.fi-selectAll-component 		:extend(.fa-check-square all){}
.fi.fi-deselectAll-component 	:extend(.fa-square all){}
.fi.fi-selectAll-category 		:extend(.fa-check-square all){}
.fi.fi-deselectAll-category 	:extend(.fa-square all){}
.fi.fi-news 	                :extend(.fa-newspaper all){ font-weight: 900 !important; }
.fi.fi-events 	                :extend(.fas all, .fa-calendar-check all){}
.fi.fi-show-select-columns      :extend(.fas all, .fa-table-columns all){}



/////////////////
/// TARANTULA
/////////////////
.fi.fi-clock										:extend(.far all, .fa-clock all){}
.fi.fi-copy											:extend(.far all, .fa-copy all){}
.fi.fi-check-square							:extend(.far all, .fa-check-square all){}
.fi.fi-envelope									:extend(.fas all, .fa-envelope all){}
.fi.fi-envelope-o								:extend(.far all, .fa-envelope all){}
.fi.fi-tarantula								:extend(.fa-code all){}
.fi.fi-service 									:extend(.fa-cog all){ font-weight: 900 !important; }
.fi.fi-services									:extend(.fa-cog all){ font-weight: 900 !important; }
.fi.fi-assignedService					:extend(.fa-cogs all){}
.fi.fi-client 									:extend(.fa-user-circle all){ font-weight: 900 !important; }
.fi.fi-clients 									:extend(.fa-user-circle all){ font-weight: 900 !important; }
.fi.fi-showInsert-client 				:extend(.fa-user-circle all){ font-weight: 900 !important; }
.fi.fi-user 										:extend(.fas all, .fa-user all){ font-weight: 900 !important; }
.fi.fi-user-o										:extend(.far all, .fa-user all){}
.fi.fi-user-plus								:extend(.fas all, .fa-user-plus all){}
.fi.fi-serviceUser 							:extend(.fa-cog all){}
.fi.fi-serviceUsers							:extend(.fa-cog all){}
.fi.fi-scope										:extend(.fab all, .fa-superpowers all){}
.fi.fi-scopes										:extend(.fab all, .fa-superpowers all){}
.fi.fi-scopeRole								:extend(.far all, .fa-user all){}
.fi.fi-scopeRoles								:extend(.far all, .fa-user all){}
.fi.fi-section									:extend(.far all, .fa-square all){}
.fi.fi-section-bulk								:extend(.fas all, .fa-list all){}
.fi.fi-times-circle							:extend(.far all, .fa-times-circle all){}
.fi.fi-minus-circle							:extend(.far all, .fa-minus-circle all){}
.fi.fi-file-text								:extend(.far all, .fa-file-alt all){}
.fi.fi-privilege 								:extend(.fa-lock all){ font-weight: 900 !important; }
.fi.fi-privileges 							:extend(.fa-lock all){ font-weight: 900 !important; }
.fi.fi-paperclip								:extend(.fas all, .fa-paperclip all){}
.fi.fi-search										:extend(.fas all, .fa-search all){}
.fi.fi-globals									:extend(.fas all, .fa-binoculars all){}
.fi.fi-userSessions									:extend(.fas all, .fa-user-clock all){ font-weight: 900 !important; }


/////////////////
/// NEWSLETTER
/////////////////
.fi.fi-newsletter 							:extend(.fa-envelope all){}
.fi.fi-mailinglist 							:extend(.far all, .fa-list-alt all){}
.fi.fi-subscriber 							:extend(.fas all, .fa-user all){ font-weight: 900 !important; }
.fi.fi-user-edit	 							:extend(.fas all, .fa-user-edit all){}
.fi.fi-segment	 								:extend(.fas all, .fa-database all){ font-weight: 900 !important; }
.fi.fi-resend	 									:extend(.fas all, .fa-share all){ font-weight: 900 !important; }


/////////////////
/// ART CRM
/////////////////
.fi.fi-address		 							:extend(.far all, .fa-address-card all){}
.fi.fi-addresses	 							:extend(.far all, .fa-address-card all){}
.fi.fi-address-type 						:extend(.fas all, .fa-address-card all){}
.fi.fi-address-type-client			:extend(.fas all, .fa-user all){}
.fi.fi-address-type-journalist	:extend(.fas all, .fa-user-tie all){}
.fi.fi-address-type-media				:extend(.fas all, .fa-users all){}
.fi.fi-address-type-contact			:extend(.fas all, .fa-address-book all){}
.fi.fi-trip 										:extend(.fas all, .fa-plane-departure all){ font-weight: 900 !important; }
.fi.fi-trips 										:extend(.fa-plane-departure all){ font-weight: 900 !important; }
.fi.fi-cooperation 									:extend(.fas all, .fa-hands-helping all){ font-weight: 900 !important; }
.fi.fi-cooperations 								:extend(.fas all, .fa-hands-helping all){ font-weight: 900 !important; }
.fi.fi-activity 								:extend(.fa-chart-area all){ font-weight: 900 !important; }
.fi.fi-activities 							:extend(.fa-chart-area all){ font-weight: 900 !important; }
.fi.fi-activities-newsletter		:extend(.fa-envelope all){ font-weight: 900 !important; }
.fi.fi-activities-mail					:extend(.fa-envelope all){ font-weight: 400 !important; }
.fi.fi-activities-competition		:extend(.fa-trophy all){ font-weight: 900 !important; }
.fi.fi-activities-lobbying			:extend(.fa-glasses all){ font-weight: 900 !important; }
.fi.fi-activities-issue					:extend(.fa-tasks all){ font-weight: 900 !important; }
.fi.fi-address-book							:extend(.fas all, .fa-address-book all){}
.fi.fi-mailing-address 					:extend(.fa-list-alt all){ font-weight: 400 !important; }
.fi.fi-mailingUrl-viewSubscribers		:extend(.fas all, .fa-user all){ font-weight: 900 !important; }


/////////////////
/// SHOP
/////////////////
.fi.fi-shop											:extend(.fas all, .fa-shopping-cart all){}
.fi.fi-shop-product							:extend(.fas all, .fa-cart-arrow-down all){ font-weight: bold !important; }
.fi.fi-shop-order								:extend(.fas all, .fa-shopping-basket all){ font-weight: bold !important; }
.fi.fi-shop-coupon							:extend(.fas all, .fa-donate all){ font-weight: bold !important; }
.fi.fi-shop-user							 	:extend(.fas all, .fa-user-shield all){ font-weight: bold !important; }
.fi.fi-euro											:extend(.fas all, .fa-euro-sign all){}
.fi.fi-clock										:extend(.fas all, .fa-clock all){}


/////////////////
/// MBLX
/////////////////
.fi.fi-reports										:extend(.fas all, .fa-book all){ font-weight: 900 !important; }
.fi.fi-robot										:extend(.fas all, .fa-robot all){ font-weight: 900 !important; }


/////////////////
/// HISTORY
/////////////////
.fi.fi-protocol										:extend(.far all, .fa-clock all){ font-weight: 900 !important; }
.fi.fi-protocol-protocol							:extend(.fas all, .fa-list all){ font-weight: 900 !important; }
.fi.fi-protocol-stats 								:extend(.fa-chart-area all){ font-weight: 900 !important; }
