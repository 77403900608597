@import "~baseVariables";
@import "~masterVariables";
.templates_mediaManagement_image_image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  & > section.head {
    width: 100%;
    border-bottom: 1px solid #CCCCCC;

    & > section.top {
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      padding: 20px;
      overflow-x: hidden;

      & > * {
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid #CCCCCC;

        h1 {
          margin-top: 0;
        }
      }
    }
  }

  & > section.main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 0;
    flex: 1 1 auto;

    & > section {
      &.left {
        width: ~"calc(100% - 400px)";
        height: 100%;

        & > section.content {
          height: 100%;
          border-right: 1px solid #cccccc;
          background-color: #FFF;
        }
      }
      &.right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 400px;
        height: 100%;

        & > section.sidebar {}

        & > section.actions {
          display: flex;
          flex: 1;
          flex-direction: column;
          border-top: 1px solid #CCCCCC;
        }
      }

      & > section {
        box-sizing: border-box;
        padding: 20px;
        overflow-x: hidden;
        width: 100%;
      }
    }
  }
}