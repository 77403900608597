@import "~baseVariables";
@import "~masterVariables";
.templates_routeTrees_routeTree {
	& > .tree {
		overflow-x: hidden;

		&.jstree-default {

			ul {

				li {
					a {
						width: calc(100% - 24px);
						padding-right: 0 !important;
					}
				}
			}
		}
	}

	.entryRightStatusContainer {
		display: flex;
		height: 24px;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		margin: -24px 0 0 24px;
		padding-right: .5em;
		font-size: 1rem;

		.entryWarning {
			margin-left: 1em;
			color: #FF8800;
		}

		.entryError {
			margin-left: 1em;
			color: #B20000;
		}

		.nav-bubbles {

			&.nav-bubbles .nav-bubble {
				display: inline-block;
				background-color: #333333;
				width: .75rem;
				height: .75rem;
				border-radius: .75rem;
				margin-left: .25rem;

				&.showTurnedOff {
					opacity: .7;
					width: .5rem;
					height: .5rem;
					margin-left: .325rem;
					margin-right: .125rem;
				}
			}
		}
	}
}