@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_propertiesForms_segmentPropertiesForm {

	.cols {
		float: left;
		width: 100%;

		& > .col {
			float: left;
			width: 100%;

			& > .base_form_elements_field {
				optgroup, textarea {
					line-height: 1.5;
				}
			}

			.fieldOf__internalMediaTags {
				display: none;
			}
		}
	}

}