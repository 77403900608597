@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_artCrm_activity_relationPropertiesForm {
	& > .buttons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		padding: 1em 0;
		box-sizing: border-box;
		border-bottom: 1px solid #CDCDCD;
	}
}