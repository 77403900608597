@import "~baseVariables";
@import "~masterVariables";
.templates_bulk_contentController {
	display: flex;
	flex-direction: row;
	height: 100%;

	& > section.selection {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 700px;

		& > section.filter {
			display: flex;
			flex-direction: row;
			height: unset;
			padding: 1em;
			border-right: 1px solid #CCCCCC;
			border-bottom: 1px solid #CCCCCC;
			box-sizing: border-box;

			& > * {
				&.templates_bulk_filter {
					width: 60%;

					.templates_bulk_elements_buttons > button {
						width: 100%;
					}
				}

				&.templates_bulk_summary {
					width: auto;
					margin-left: auto;
					margin-top: unset;
				}
			}
		}
		& > section.list {
			flex: 1;
			border-right: 1px solid #CCCCCC;
			box-sizing: content-box;
			overflow: hidden;
		}
	}
	& > section.content {
		display: flex;
		flex-direction: column;
		width: ~"calc(100% - 700px)";
		height: 100%;
	}
}

.base_modal_modal.container_templates_bulk_contentController {
	& > .window {
		width: 96%;
		height: 90%;
		top: 3%;
		left: 2%;
	}
}