@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_bulk_moveComponentTargetControls {
  & > .cols {
    & > .col {
      display: flex;
      flex-direction: row;
      justify-content: center;

      & > .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;

        & > button {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          height: 7em;
          width: 9em;

          & > i {
            width: 1em;
            font-size: 2.5em;

            &.fi-arrow-up,
            &.fi-arrow-down {
              padding-top: .125em;
            }
          }
        }
      }
    }
  }
}