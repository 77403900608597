@import "~baseVariables";
@import "~masterVariables";
.templates_profileControls_profileControls {

	section {
		padding: 1em;
		box-sizing: border-box;

		&.summary {
			width: 70%;
		}

		&.serviceCollectionOptions {
			float: right;
			width: 30%;
		}
	}
}