@import "~baseVariables";
@import "~masterVariables";
.templates_propertiesForms_componentDataSelectFieldCodesForm {
  & > .cols {
    & > .col {
      display: flex;
      flex-direction: column;

      .fieldWrapper {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        margin-top: .5em;

        & > .control {
          margin-right: 1em;
          padding-top: 7px;
        }
        & > .element {
          flex: 1 1 auto;

          & > .field {

            &,
            &.disabled {
              & > label.base_form_elements_label {
                color: #000000;
              }
            }
          }
        }
      }

    }
  }
}