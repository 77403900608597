@import "~baseVariables";
@import "~masterVariables";
.base_modal_modal.container_templates_tags_tagAssignment.notEnhanced .window {
	width: 60%;
	height: 80%;
	left: 20%;
	top: 5%;
}

.templates_tags_tagAssignment {
	display: flex;
	flex-direction: row;
	overflow: hidden;
	color: #333333;
	height: 100%;

	& > section {
		padding: 1.5em;
		height: 100%;
		overflow: auto;
		box-sizing: border-box;

		&.enhanced {
			flex: 0 1 30%;
			border-right: 1px solid #CCCCCC;
		}

		&.tagsOnly {
			display: flex;
			flex-direction: column;
			flex: 1;
			padding: 0;

			& > section {
				padding: 1.5em;

				&.filter {
					border-bottom: 1px solid #CCCCCC;
				}

				&.content {

					& > .category {
						display: flex;
						flex-direction: row;
						align-items: center;
						font-size: 1.5em;
						margin: .75em 0 .375em 0;
						padding-bottom: .125em;
						border-bottom: 1px solid #CCCCCC;

						&:first-child {
							margin: 0 0 .5em 0;
						}

						& > i {
							transition: opacity .2s ease-in-out;
							margin-right: .25em;
							display: none;

							&.selected {
								opacity: 0;
							}

							&.inherited {
								opacity: 0;
								font-size: .5em;
								margin-top: -2em;
								margin-left: -1em;
							}

							&.unselected {
								margin-right: -1em;
							}
						}

						&.selected {
							& > i.selected {
								opacity: 1;
							}
						}

						&.inherited {
							& > i.inherited {
								opacity: 1;
							}
						}

						&.showOption {
							& > i {
								display: unset;
							}
						}

						&:hover {
							cursor: pointer;

							i {
								color: #888888;
							}
						}

						&.dirty {
							i {
								color: #888888;
							}
						}
					}

					.categoryDataContainer {
						display: flex;
						flex-direction: column;
						padding: 0 0 .375rem 1.5rem;
					}

					& > .categoryData, .categoryDataContainer > .categoryData {
						display: flex;
						flex-direction: row;
						align-items: center;
						font-size: 1em;
						padding: .125rem 1px .125rem .75rem;
						box-sizing: border-box;
						width: 100%;

						& > button {
							&, &:focus, &:active {
								background-color: transparent;
								border: none;
								outline: none;
							}

							&.tag {
								display: flex;
								flex-direction: row;
								align-items: center;
								width: 100%;

								i {
									transition: opacity .2s ease-in-out;
									margin-right: .25em;

									&.selected {
										opacity: 0;
									}

									&:not(.selected) {
										margin-right: -1em;
									}
								}
							}

							&.collapseController {
								display: flex;
								flex-direction: row;
								justify-content: center;
								width: 1em;
								box-sizing: border-box;

								&:hover {
									cursor: pointer;
								}
							}
						}

						&.noChildren {
							padding-left: 1.75rem;

							& > button.collapseController {
								display: none;
							}
						}



						&.selected > button.tag {
							i.selected {
								opacity: 1;
							}
						}

						&:hover {

							& > button.tag {
								cursor: pointer;
								background-color: #FFF8DF;
								outline: 1px solid #DFDFDF;

								i {
									color: #888888;
								}
							}
						}

						&.dirty > button.tag {
							i {
								color: #888888;
							}
						}
					}

					.categoryData:not(.collapsed) > button.collapseController > i.closed {
						display: none;
					}
					.categoryData.collapsed > button.collapseController > i.open {
						display: none;
					}

					.categoryData.collapsed + .categoryDataContainer {
						height: 0;
						padding: 0;
						overflow: hidden;
					}
				}
			}
		}
	}

	&.filterActive {
		.categoryData {
			&, & + .categoryDataContainer {
				display: none !important;
			}

			&.matcher {
				&, & + .categoryDataContainer {
					display: flex !important;
				}
				& > button.tag {
					font-weight: bold;
				}
			}
			&.parentOfMatcher {
				&, & + .categoryDataContainer {
					display: flex !important;
				}
			}
			&.childOfMatcher {
				&, & + .categoryDataContainer {
					display: flex !important;
				}
			}
		}
	}

	&.dirtyColorNormal {
		& > .tagsOnly {
			& > .content {
				& > .categoryData, .categoryDataContainer > .categoryData {
					&.dirty {
						& > button {
							i {
								color: #333333;
							}
						}
					}
				}
			}
		}
	}

	&.preventSelectParents {

		& > section {

			&.tagsOnly {

				& > section {

					&.content {

						.categoryDataContainer {
							padding-left: 2rem;
						}

						& > .categoryData, .categoryDataContainer > .categoryData {

							& > button {

								&.tag {

									i {

										&.selected {
											margin-right: .375rem;
										}
									}
								}
							}

							&.noChildren {
								padding-left: .375rem;
							}
						}
					}
				}
			}
		}
	}
}