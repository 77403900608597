@import "~baseVariables";
@import "~masterVariables";
.templates_login_container {
  float: none;
  width: 80%;
  //height: 30%;
  background-color: #F9F9F9;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  overflow: hidden;
  padding: 25px;
  margin: 10% auto;

  .buttons {
    margin-top:20px;
    padding-bottom: 0;
  }

  button[data-field-type=submit],
  button[data-field-type=submit]:hover,
  button[data-field-type=submit]:focus {
    background-color: #572C00;
    color: #FFFFFF;
    border-color: #FFFFFF;
  }

  .base_tabStrip_tabStrip {

    section.tabContents {
      height: initial;

      .tabContent {
        height: initial;
      }
      .base_simple_paragraph_paragraph {
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 20px;
        color: #572C00;
      }
    }
  }

  @media (min-width: 600px) {
    width: 60%;
    //height: 50%;
  }

  @media (min-width: 1000px) {
    width: 500px;
    //height: 300px;
  }
}

.base_modal_modal {
  .templates_login_container {
    height: initial;
    background-color: #F9F9F9;
    border: none;
    padding: 0;
    margin: 4% auto;
  }
}