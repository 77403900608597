@import "~baseVariables";
@import "~masterVariables";
.templates_tags_tagCategoryManagementButton {
	font-size: 1rem;
	padding: .25em .75em;
	margin-left: 1em;
	background-color: #EFEFEF;
	border: 1px solid #CCCCCC;
	border-radius: 3px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	& > i {
		color: #333333 !important;
		margin-right: .5em;
	}

	&:hover {
		cursor: pointer;
	}
}