@import "~baseVariables";
@import "~masterVariables";
.templates_categoryTrees_categoryTrees {

  & > section.filter {
    justify-content: space-between;

    & > .mainTreeActionsForm {
      padding: 8px 20px 0 0;
    }
  }
}

.templates_categoryTrees_categoryTrees.mode_tagManagement {
  & > section.filter > .mainTreeActionsForm {
    display: none;
  }
}
