@import "~baseVariables";
@import "~masterVariables";
.templates_nav_nav {
	display: flex;
	flex-direction: column;
	flex: 0 0 auto;
	width: 256px;

	font-family: @baseFontFamily;
	font-size: 14px;
	color: #000000;
	background-color: #E7E6E4;
	padding-top: 3em;
	box-sizing: border-box;
	border-right: 1px solid #CCCCCC;

	transition: width .3s ease-in-out, opacity .3s ease-in-out !important;

	& > ul {

		& > li {
			border-radius: 5px;
		}

		&, & > li > .outerSubNav > ul {
			display: flex;
			flex-direction: column;
			height: 100%;
			list-style: none;
			padding: 6px 10px;
			margin: 0;
			color: #000000;
			background-color: #E7E6E4;

			& > li {
				position: relative;
				display: none;
				flex-direction: column;
				padding: 0;
				margin: 0 0 1px 0;
				cursor: pointer;
				line-height: 32px;
				height: 32px;

				& > .entry {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 0 .5em;

					& > .symbol {
						display: flex;
						flex-direction: row;
						justify-content: center;
						flex: 0 0 auto;
						width: 1.5em;
						padding-right: .5em;

						& > i {
							line-height: 32px;
						}
					}

					& > .title {
						padding-right: .5em;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					& > .indicator {
						margin-left: auto;

						& > i.active {
							display: none;
						}
					}
				}

				// main menu entry
				&[data-level="0"] {
					display: flex;
				}

				&.head {
					display: none;

					&.info {
						cursor: default;
						background-color: #D7D7D7;
						font-weight: normal;

						& > .entry {
							& > .title {
								padding: 0;
							}
						}
					}
				}

				&.visible {
					display: flex;
				}

				&.active {
					background-color: #D7D7D7;

					& > .entry {
						font-weight: 600;

						& > .indicator > i {
							&.active {
								display: block;
							}
							&.inactive {
								display: none;
							}
						}
					}
				}

				&:hover {
					cursor: pointer;
					background-color: #D7D7D7;
				}

				&:hover:not(.active) {
					& > .outerSubNav {
						display: flex;

						& > ul > li:not(.head) {
							display: flex;
						}
					}
				}

				&.collapseToggle {
					margin-top: auto;

					& > .entry > .symbol > i {
						display: none;

						&.expanded {
							display: block;
						}
					}
				}

				& > .outerSubNav {
					position: absolute;
					z-index: 2;
					padding: 0 0 0 20px;
					display: none;
					left: 100%;

					& > ul {
						border-radius: 5px;
						padding: 0;
						box-shadow: 2px 2px 2px #999999;
						overflow: hidden;

						& > li {

							&.head {
								display: none;
								background-color: unset;
								border-bottom: 1px solid #666666;
								margin-bottom: 0;

								&.info {
									border-bottom: none;
								}
							}

							&:last-of-type {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		& > li > .outerSubNav > ul {
			border: 1px solid #666666;

			& > li {
				& > .entry {
					& > .title {
						padding: 0 1em;
					}
				}

				&.head {
					& > .entry {
						& > .title {
							padding: 0 1em 0 0;
						}
					}
				}
			}
		}

		& > li.isUserProfile {
			& > .entry {
				& > .symbol {
					width: 38px;

					& > i {
						font-size: 24px;
					}
				}
			}
		}
	}

	&.alignRight {
		& > ul {

			&, & > li > .outerSubNav > ul {

				& > li {

					& > .outerSubNav {
						left: unset;
						right: 0;
					}
				}
			}
		}
	}

	&.collapsed {
		width: calc(2.5em + 20px);

		& > ul {

			& > li {
				display: none;

				&[data-level="0"] {
					display: flex;
				}

				& > .entry {

					& > .title {
						display: none;
					}

					& > .indicator {
						display: none;
					}
				}

				& > .outerSubNav {
					top: -100%;

					&.headOnly {
						top: unset;
					}

					& > ul {

						& > li {

							&.head.visible {
								display: flex;
							}
						}
					}
				}

				&.collapseToggle > .entry > .symbol > i {
					display: none;

					&.collapsed {
						display: block;
					}
				}

				&:hover {
					& > .outerSubNav {
						display: flex;

						& > ul > li {
							display: flex;
						}
					}
				}

				&.forceCollapsedTitle {
					& > .entry {
						& > .title {
							display: flex;
						}
					}
				}
			}
		}
	}

	&.horizontal {
		width: auto;
		padding-top: 0;

		& > ul {
			flex-direction: row;
			align-items: center;

			& > li {
				margin: 0 0 0 .5em;

				& > .outerSubNav {
					top: 100%;
					padding: 16px 0 0 0;
				}
			}
		}

		&.collapsed {
			width: auto;

			& > ul {

				& > li {
					& > .entry {
						& > .symbol {
							padding-right: 0;
						}

						& > .indicator {
							padding-left: .5em;
							display: flex;
						}
					}
				}
			}

			&.alignRight {
				& > ul {

					& > li {

						& > .outerSubNav {
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}

.appContainer > .top > .templates_nav_nav {
	background-color: transparent;

	& > ul {
		color: #FFFFFF;
		background-color: transparent;

		& > li {
			border-radius: 5px;

			&.active {
				background-color: #814101;
			}

			&:hover {
				background-color: #814101;
			}
		}
	}
}