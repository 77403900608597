@import "~baseVariables";
@import "~masterVariables";
.templates_mediaManagement_mediaDetailsSummary {
	display: inline-flex;
	flex-direction: row;
	align-items: flex-start;
	flex: 1 1 100%;
	border: 0 !important;
	margin: 0 !important;
	padding: 0 !important;

	& > table {
		flex: 1 1 auto;
		border-spacing: 0;
		border: 0;

		&.permissions {
			flex: 0 0 12em;
		}

		& > tbody {

			& > tr {

				& > td {
					font-size: .875em;
					line-height: 1.25;

					&:first-child {
						width: 60px;
						padding-right: 10px;
						font-weight: bold;
						white-space: nowrap;
					}

					&:last-child {
						word-break: break-all;
					}

					& > div.largeContentHover {
						display: block;
						height: 1.3em;
						overflow: hidden;

						&:hover {
							/* max-height: 1.3em; */
							height: unset;
							//display: block;
							position: absolute;
							background-color: #e7e6e4;
							padding: 5px 12px;
							max-width: 80%;
							border-radius: 2px;
							border: 1px solid #333333;
							/* word-break: break-all; */
							/* overflow-wrap: break-word; */
							margin: -15px 0 0 -13px;
						}
					}
				}

				//&:last-child {
				//
				//	& > td:last-child {
				//		display: block;
				//		height: 1.3em;
				//		overflow: hidden;
				//	}
				//}
			}
		}
	}

	& > ul {
		flex: 1 1 auto;
		padding: 0;
		margin: 0;
		list-style-type: none;
		font-size: .75em;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap-reverse;
		max-height: 100px;

		& > li {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			flex: 0 1 1em;
			padding: 2px 0 2px 2em;

			&:nth-child(6n + 1){
				padding: 0 0 2px 2em;
			}
			&:nth-child(6n + 6){
				padding: 2px 0 0 2em;
			}

			& > i {
				padding: .125em 0 0 .5em;
			}

			& > span {
				text-align: right;
			}
		}
	}
}
