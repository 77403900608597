@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_edit_content_variables {

	padding-bottom: 10px;

	.separator {
		width: 100%;
		border-bottom: 1px solid #CCCCCC;
		margin: 10px 0 20px 0;
	}

	button {
		cursor: pointer;
		//font-family: "Open Sans", Arial, sans-serif;
		font-size: 14px;
		padding: 7px 10px;
		background-color: #efefef;
		color: #572C00;
		border: 1px solid #572C00;
		border-radius: 3px;

		i {
			margin-right: 5px;
		}

		&:hover {
			color: #FFFFFF;
			background-color: #572C00;
		}
	}

	.variables {
		display:none;

		span {
			cursor: pointer;
			font-size: 13px;
			color: darkred;

			i {
				margin: 0 3px 0 5px;
			}
		}

		table {
			width: 100%!important;
			tr {
				td {
					font-size: 12px;

					&:first-child {
						width: 30%!important;
					}
				}
			}
		}
	}
}