@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_artCrm_entity_propertiesForm.templates_artCrm_cooperation_propertiesForm {

	.cols {

		.col {
			width: 40%;

			&:nth-child(1) {
				width: 40% !important;
			}
			&:nth-child(2) {
				width: 60% !important;
			}

			/* FIELDS AND GROUP'S FIELDS */
			& > .group > .field,
			& > .field {

				&.fieldOf_cooperation > .base_form_fields_textarea {
					&, & > textarea {
						width: 100% !important;
						min-height: 368px;
						color: #AA0000;
					}
				}
			}
		}
	}
}