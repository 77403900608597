@import "~baseVariables";
@import "~masterVariables";
.templates_profileControls_profileController {
	position: absolute;
	display: flex;
	transform: translateX(-50%);
	left: 50%;
	align-items: center;
	bottom: .3em;
	z-index: 1000;

	ul.panel {
		list-style-type: none;
		background-color: #444444;
		color: #EDEDED;
		border-radius: .2em;
		margin: 0;
		padding: 0 .5em;

		li {
			display: inline-flex;
			margin-right: .3em;
			padding: .3em;
			font-size: 1em;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

}