////////////////////////////////////////////////
// BASE COMPONENTS
////////////////////////////////////////////////

// TODO: why is this file a thing?!
// special cms default styles for autoComplete pickers
.base_form_fields_autoComplete {
  font-size: .875em;

  & > .main {

    & > .value {
      width: calc(100% - 5.5em);
      text-overflow: ellipsis;
      word-break: keep-all;
      white-space: nowrap;
      box-sizing: border-box;
      overflow: hidden;
      font-size: 1em;
    }
  }

  & > .optionList {

    ul {

      li {
        text-overflow: ellipsis;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
