@import "~baseVariables";
@import "~masterVariables";
.base_modal_modal.container_templates_ttgShop_coupons_propertiesForm {
	& > .window {
		width: 40%;
	}
}

.base_form_form.templates_ttgShop_coupons_propertiesForm {
	& > .cols {

		& > .col {

			& > h1 {
				margin-top: 0;
				font-weight: 400;
			}

			.base_form_fields_text.textfield-integer,
			.base_form_fields_text.textfield-float {
				float: left;
				width: 10em !important;
			}
		}
	}
}