@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailingUrls_summary {
	display: inline-flex;
	font-style: italic;
	font-size: 14px;
	margin-top: auto;

	& > tbody {
		& > tr {
			& > td {
				&.value {
					padding-left: 1em;
				}
			}
		}
	}
}