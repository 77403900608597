@import "~baseVariables";
@import "~masterVariables";
.templates_scopeTrees_selectScopeRoles {

	.row {
		width: 100%;
		margin-bottom: 1%;

		label {
			width: 29%;
			display: inline-block;
			text-align: right;
		}

		> div {
			width: 68%;
			padding-left: 2%;
			text-align: left;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}