@import "~baseVariables";
@import "~masterVariables";
.templates_treesManagement_subActions {

  //flex: 1;
  display: flex;
  //padding-top: 20px;
  margin-top: auto;

  .buttons {

    button {
      float: left;

      margin: 10px 0 0 0;

      &.insertRoot {
        background-color: saddlebrown;
        color: white;
        font-weight: bold;
        font-size: 2em;
        content: "+"
      }
    }
  }
}