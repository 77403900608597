@import "~baseVariables";
@import "~masterVariables";
.base_tabStrip_tabStrip.templates_newsletter_mailings_edit_tabStrip_tabStrip {

	section {

		&.tabs {
			padding: 5px 0 0 0;
			border-bottom: 2px solid @primaryColor;
		}

		.tabContent  {
			background-color: #ffffff;
		}
	}
}