@import "~baseVariables";
@import "~masterVariables";
.templates_ninja_controller {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 1.25em;
    text-align: center;
    margin: 5em 0 2em 0;
    line-height: .875;
  }

  & > .buttons {
    justify-self: center;
    flex: 1 1 auto;
    font-size: 1.25em;

    & > button {
      font-size: 1em;

      & > i {
        font-size: 1em;
        margin-right: .75em;
      }
    }
  }

  & > section.content {
    width: 100%;

    & > .templates_treesManagement_propertiesForm {
      padding: 1em;
      margin: 0;
      box-sizing: border-box;
      max-width: 800px;
    }
  }
}
