@import "~baseVariables";
@import "~masterVariables";
.templates_mblx_products_additionalInformation_stats_doughnut {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-top: auto;

	& > h2 {
		text-align: center;
		font-size: 18px;
		color: @primaryColor;
		margin: 0;
	}

	& > .canvasContainer {
		width: 50%;
		padding: 1em;
	}

	& > .legend {
		width: 100%;
		padding-left: .25em;

		& > ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			font-size: 14px;

			& > li {
				display: inline-flex;
				flex-direction: row;
				justify-content: flex-start;
				margin-bottom: .5em;
				width: 100%;

				& > span {
					width: 2.5em;
					height: 1em;
					margin-right: .5em;
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}