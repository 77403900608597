@import "~baseVariables";
@import "~masterVariables";
.templates_componentTrees_componentTrees {

  & > section.filter {
    justify-content: space-between;

    & > .mainTreeActionsForm {
      padding: 8px 20px 0 0;
    }
  }
}
