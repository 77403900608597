@import "~baseVariables";
@import "~masterVariables";
.templates_bulk_contentController.templates_bulk_chooseBulkSectionController {
   & > section.selection {
     width: 57%;

     & > section.controls {
       padding: 20px;
       border-top: 1px solid #CCCCCC;
       border-right: 1px solid #CCCCCC;
     }
   }
   & > section.content {
     width: 43%;
   }
}