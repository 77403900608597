@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_segments_head {

	width: 100%;
	height: 41px;
	overflow: hidden;
	background-color: #F9F9F9;
	border-bottom: 1px solid #CCCCCC;
	display: flex;
	flex-direction: column;

	div {
		text-align: right;
	}

	button {
		//font-family: "Open Sans", Arial, sans-serif;
		font-size: 14px;
		padding: 7px 10px;
		cursor: pointer;
		color: #FFFFFF;
		background-color: #572C00;
		border: 1px solid #572C00;
		border-radius: 3px;
		margin-top: 5px;
		margin-right: 1em;

		&:hover {
			background-color: #efefef;
			color: #572C00;
		}

		i {
			margin-right: 3px;
			width: 16px;
		}
	}
}