@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_artCrm_activity_propertiesForm {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: auto;

	& > .base_form_form.base_form_relationship {

		& > .base_form_form.subForm {
			border-bottom: 1px solid #CDCDCD;
			box-sizing: border-box;
			font-size: .875em;

			&:nth-child(even) {
				background-color: #EAEAEA;
			}

			& > .cols {

				& > .col {
					width: 100%;
					padding: 1.5em;

					& > .group > .field,
					& > .field {
						& > label {
							color: #777;
							text-transform: uppercase;
							font-size: 10px;
						}
					}


					& > .field.fieldOf_date {
						width: 210px !important;
					}
					& > .group.title-description {
						margin-right: 0;
					}
					& > .field > *[data-field-code="description"] {
						line-height: 1.25;
					}
					& > .field.disabled {
						display: none;
					}
				}
			}
		}
	}
}

.container_base_form_form.container_templates_artCrm_activity_propertiesForm .window {
	width: 50%;
	height: 60%;
	left: 25%;
	top: 15%;
}