@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_edit_preview_preview {
	background-color: #FFF;
	height: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;

	.wrapper {
		display: flex;
		padding: 1em 2em;

		& > div {
			flex: 1 0 0;
		}

		select {
			padding: 7px 9px;
			font-size: .875em;
			border: 1px solid #CCCCCC;
			max-width: 300px;
			margin-right: 30px;
		}

		button {
			cursor: pointer;
			padding: 8px 10px;
			font-size: .875em;
			border: 1px solid #CCCCCC;
			background-color: #DCDCDC;
			color: #000000;

			&.active {
				color: #FFFFFF;
				background-color: @primaryColor;
			}

			&:last-child {
				margin-left: 30px;
			}
		}
	}

	iframe {
		flex-grow: 1;

		&.desktop {
			width: auto;
			margin: 0 2em 2em 2em;
		}

		&.mobile {
			width: 320px;
			margin: 0 auto 1em;
		}
	}
}