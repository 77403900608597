@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_mediaManagement_actionsForm {

  h1 {
    font-size: 1rem;
  }

  button, button.negative {
    width: 100%;
    float: left;
    clear: left;
    margin: 0 0 0 10px;
    background-color: transparent !important;
    border: none;
    color: #572C00 !important;
    text-align: left;
    font-size: .875rem;
    padding: 5px 10px;

    &:hover {
      color: #000000;
      background-color: transparent;
    }
  }

  .cols .col .field > *:not(.fieldIndependent) {
    width: 100% !important;
  }
}
