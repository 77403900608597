@import "~baseVariables";
@import "~masterVariables";
.templates_artCrm_activity_recordController {

	& > section.content {

		& > section.main {
			padding: 0 !important;
		}
	}
}