@import "~baseVariables";
@import "~masterVariables";
.templates_protocol_protocol_table_table {
	font-size: 13px;

	& > section.head {
		padding: 10px 10px 10px 1rem;

		& > h2 {
			width: 100%;
			margin: 0;
			padding: 7px 0 6px 0;
			font-size: 1.25rem;
			color: @primaryColor;
			text-transform: uppercase;
			text-align: center;
		}
	}

	& > table > thead > tr > td,
	& > table > tbody > tr > td {
		font-weight: normal;

		&.headOf_method,
		&.fieldOf_method {
			text-align: center;
		}

		&.base_tableSimple_colActions {
			& > .buttons {
				& > div {
					display: flex;

					&.left {
						margin-right: 3px;
					}
				}
			}
		}
	}


	&.emptyTable {

		& > table > thead > tr > td,
		& > table > tbody > tr > td {

			&.emptyTableMessage {
				border-right: none;
			}
		}
	}
}
