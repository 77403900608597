@import "~baseVariables";
@import "~masterVariables";
.templates_mediaManagement_imageAssignmentDetails_renderedImageList {
  position: relative;
  width: 100%;
  height: 100%;

  .deviceContainer, .breakPointContainer {
    position: relative;
    float: left;
    width: 100%;
    border-top: 1px solid gray;
    margin-top: 10px;
    padding: 12px 0;
    box-sizing: border-box;

    .containerTitle {
      position: relative;
      float: left;
      margin: -22px 30px -22px;
      padding: 0 10px;
      background-color: #F9F9F9;
    }
  }

  .breakPointContainer {
    padding: 12px 20px;
  }
}
