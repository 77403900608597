@import "~baseVariables";
@import "~masterVariables";
.templates_mediaManagement_image_image.templates_mediaManagement_artImageArchive_image {
	& > section.head > section.top > .invisible {
		padding: 0;
		margin: 0;
		border: none;
	}

	& > section.main {
		& > section.left {
			width: 100%;
		}

		& > section.right {
			display: none;
		}
	}

	.templates_mediaManagement_filterForm {
		& > .cols > .col .group.filter {
			display: flex;
			flex-direction: row;

			& > .field {
				width: 100% !important;
			}

			& > .fieldOf_artiasTimeRange {
				width: 50% !important;

				& > div > input {
					width: calc(100% - 30px);
				}
			}
		}
	}
}