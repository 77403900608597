@import "~baseVariables";
@import "~masterVariables";
.templates_placeholder_placeholder {
	width: 100%;
	height: 100%;
	position: relative;

	.center {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		img {
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			margin-bottom: 15px;
		}

		h1 {
			text-align: center;
		}
	}
}