@import "~baseVariables";
@import "~masterVariables";
.templates_serviceUserTrees_serviceUserTrees  {
  & > section.filterContent {
    & > section.colWrap {
      & > section.treeContainer {
        width: 20%;
      }

      & > section.properties {
        width: calc(60% - 1px);
      }
    }
  }
}