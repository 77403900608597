@import "~baseVariables";
@import "~masterVariables";
.base_tabStrip_tab.templates_newsletter_mailings_edit_tabStrip_tab {
	display: flex;
	flex-direction: column;
	padding: 2em;

	&.item {
		cursor: pointer;
		float: left;
		font-size: 16px;
		vertical-align: middle;
		text-decoration: none;
		background: none;
		border: none;
		border-bottom: none;
		border-right: 2px solid #EBEBEB;
		border-radius: 0;
		color: @primaryColor;
		margin-bottom: -1px;
		margin-left: 0;
		padding: 15px 30px 20px 30px;
		z-index: 1;

		&.closable {
			padding-right: 10px;
		}

		i {
			cursor:pointer;
		}

		&:first-child {
			margin-left: 0;
		}

		button {
			float: right;
			background: none;
			border: none;
			margin: -5px -8px 0 5px;
			color: #333;
			line-height: inherit;

			&:hover {
				color: #CCCCCC;
				cursor: pointer;
			}
		}
		.tab-icon {
			margin-right: 3px;
		}

		&:hover {
			color: #333;
		}

		&.active {
			color: @primaryColor;
			font-weight: normal;
		}
	}

	i.statusSymbol {
		margin-left: -12px;
		top: 3px;

		&.validationFailedSymbol {
			color: #B20000;
		}
	}

	&.active {
		border-bottom: 11px solid @primaryColor;
	}

	& > div.title {
		padding-bottom: 5px;
		font-weight: 600;

		i {
			margin-left: 10px;
		}
	}
	& > div.subTitle {
		font-size: 13px;
		color: #666;
	}
}