@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_edit_stats_stats {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;

	& > section {
		display: flex;
		flex-direction: column;
		height: 100%;

		&.main {
			width: 30%;
			justify-content: center;
			padding: 0 2rem;
		}
		&.details {
			width: 484px;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			padding: 16px 8px;
			box-sizing: border-box;

			& > * {
				width: 160px;
				height: 160px;
				padding: 8px 32px;
			}
		}
		&.info {
			width: ~"calc(70% - 484px)";
		}
	}
}