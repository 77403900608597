@import "~baseVariables";
@import "~masterVariables";
.templates_mediaManagement_image_imageList {
  display: flex;
  flex-direction: column;

  & > .imageList {
    width: 100%;
  }
  & > .newImages {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > .title {
      font-size: 1em;
      border-bottom: 1px solid #808080;
      margin-bottom: 12px;
      padding: 0 0 3px 12px;
      font-style: italic;
    }

    & > .newImageList {
      width: 100%;
      margin-bottom: 12px;
      padding: 0 70px;
      box-sizing: border-box;
      border-bottom: 1px solid #808080;
    }

    & > * {
      display: none;
    }

    &.active > * {
      display: block;
    }
  }

  & > .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: .75em;
    border-top: 1px solid gray;

    & > button {
      font-size: 1.125em;
    }

    & > button > i {
      font-size: 1.125em;
      margin-right: .25em;
    }
  }
}