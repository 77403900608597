@import "~baseVariables";
@import "~masterVariables";
@labelWidth: 140px;

.base_form_form.templates_artCrm_cooperation_filter {

	& > .cols {

		& > .col {

			/* FIELDS AND GROUP'S FIELDS */
			& > .group > .field,
			& > .field {

				& > *:not(.fieldIndependent) {
					width: ~"calc(100% - @{labelWidth})" !important;
				}

				& > label:not(.fieldIndependent) {
					width: @labelWidth !important;
					flex: 1 0 auto;
				}

				& > .base_form_fields_tag-picker-button .base_form_fields_toggle,
				& > .base_form_fields_toggle {
					--toggle-horizontal-padding: 12px;
				}
			}
		}
	}
}