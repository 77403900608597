@import "~baseVariables";
@import "~masterVariables";
.templates_mblx_products_table_table {
	font-size: 13px;

	& > section.head {
		padding: 10px 10px 10px 1rem;

		& > h2 {
			width: 100%;
			margin: 0;
			padding: 7px 0 6px 0;
			font-size: 1.25rem;
			color: @primaryColor;
			text-transform: uppercase;
			text-align: center;
		}
	}

	& > table > thead,
	& > table > tbody {

		& > tr > td {
			font-weight: normal;

			&.headOf_hasRouteComponents,
			&.fieldOf_hasRouteComponents,
			&.headOf_status,
			&.fieldOf_status {
				text-align: center;
			}

			&.base_tableSimple_colActions {
				& > .buttons {
					& > div {
						display: flex;

						&.left {
							margin-right: 3px;
						}

						& > button {
							display: flex;
							flex-direction: row;
							justify-content: center;

							& > i {
								display: inline-flex;
								max-width: 10px;
							}

							&[data-field-type="openArticleUrl"] {
								visibility: hidden;
							}
						}
					}
				}
			}
		}

		&.hasArticleUrl {
			& > tr > td {

				&.base_tableSimple_colActions {
					& > .buttons {
						& > div {

							& > button[data-field-type="openArticleUrl"] {
								visibility: visible;
							}
						}
					}
				}
			}
		}
	}



	&.emptyTable {

		& > table > thead > tr > td,
		& > table > tbody > tr > td {

			&.emptyTableMessage {
				border-right: none;
			}
		}
	}
}