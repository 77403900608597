@import "~baseVariables";
@import "~masterVariables";
.templates_tags_tagAssignmentFilter,
.base_form_form.templates_tags_tagAssignmentFilter .cols .col {
	padding: 0;
	align-items: flex-end;

	.field {
		margin-bottom: 0;

		& > *:not(.fieldIndependent){
			width: 100% !important;
			float: unset;
		}

		&.fieldOf_selected {
			width: auto !important;
		}
	}
}