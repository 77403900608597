@import "~baseVariables";
@import "~masterVariables";
.templates_protocol_protocol_actionFormInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  & > .inner {
    width: 100%;

    & > p {
      width: 100%;
      text-align: center;
      margin: 0 0 10px 0;
    }
  }

  & > .outer {
    position: fixed;
    width: 340px !important;
    min-height: 120px;
    transform: translate(calc(100% + 40px), -20px);
    background-color: #F9F9F9;
    border: 1px solid #CCCCCC;
    border-radius: .5em;
    padding: 20px;
    box-sizing: border-box;

    & > table {
      table-layout: fixed;
      width: 100%;
      font-size: 0.875em;
      color: inherit;

      & > tbody {
        & > tr {
          & > td {
            &.head {
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &.body {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-break: keep-all;
            }
          }
        }
      }
    }
  }

}
