@import "~baseVariables";
@import "~masterVariables";
.templates_treesManagement_treesManagement {

  & > section {

    &.filter {
      justify-content: space-between;

      & > .mainTreeActionsForm {
        padding: 8px 20px 0 0;
      }
    }
  }
}
