@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_table_fields_sendStatusChart {
	display: flex;
	flex-direction: row;
	flex: 0 0 auto;
	justify-content: flex-start;
	width: 250px;
	height: 15px;
	overflow: hidden;
	//border: 1px solid #333333;
	box-sizing: border-box;

	& > .bar {
		&.other {
			background-color: #EEEEEE;
		}
		&.sent {
			background-color: #90c190;
		}
		&.received {
			background-color: #006600;
		}
		&.undeliverable {
			background-color: #B20000;
		}
	}
}