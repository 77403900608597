@import "~baseVariables";
@import "~masterVariables";
@contactLabelWidth: 160px;

.base_form_form.templates_artCrm_address_propertiesForm {

	&:not(.isHorizontal) {

		&.treatAsContact {

			.cols {

				.col {

					/* GROUPS AND FIELDS */
					& > .group,
					& > .field {

						& > *:not(.fieldIndependent) {
							width: ~"calc(100% - @{contactLabelWidth})" !important;
						}

						& > label:not(.fieldIndependent) {
							width: @contactLabelWidth !important;
						}
					}
				}
			}
		}

		.cols {

			.col {

				/* GROUPS */
				& > .group {

					&.type {

						& > .fieldOf_contactType {
							width: 50% !important;
						}
					}

					&.street {

						& > .fieldOf_street {
							width: 100% !important;
						}
						& > .fieldOf_streetNr {
							width: 15% !important;
						}
					}

					&.zipCityCountry {

						& > .fieldOf_zip {
							width: 50% !important;
						}

						& > .fieldOf_city {
							width: 100% !important;
						}

						& > .fieldOf_country {
							width: 100% !important;
						}
					}
				}

				/* FIELDS AND GROUP'S FIELDS */
				& > .group > .field,
				& > .field {
					margin-bottom: 8px;

					&.fieldOf_media_circulation,
					&.fieldOf_media_pagePrice {
						& > input {
							text-align: left;
						}
					}

					&.fieldOf_info > .base_form_fields_textarea {
						&, & > textarea {
							width: 100% !important;
							min-height: 192px;
							color: #AA0000;
						}
					}

					& > .base_form_fields_tag-picker-button:not(.fieldIndependent) {
						width: 100% !important;
						padding-bottom: 2em;

						& > section.top {
							margin-bottom: 0;
							border-bottom: 1px solid #9A9A9A;

							& > .topTitle {
								font-size: 1.325rem;
								font-weight: bold;
								padding: .5rem 0;
							margin: 0;
								text-transform: uppercase;
								color: #666;
							}

							& > button {
								box-sizing: border-box;
								font-size: .875em;
								margin: .25rem 0 .25rem auto;
							}
						}

						& > .tags {
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							padding: .5em 0 0 0;

							& > .category {
								padding-left: 1rem;
							}
						}
					}
				}

				& > .group > .field {
					padding-right: 8px;

					&:last-child {
						padding-right: 0;
					}
				}
			}
		}

		&.treatAsContact {

			.cols {

				.col {
					width: 50% !important;
					box-sizing: border-box;
					padding-left: 1em;
					padding-top: ~"calc(1em + 42px)";

					&:nth-child(1){
						width: 50%;
						padding-left: 0;
						padding-top: 0;
					}
					&:nth-child(2){
						width: 50%;
						padding-top: 0;
					}
					&:nth-child(3){
						width: 34%;
						padding-left: 0;
					}
					&:nth-child(4){
						padding-top: 1em;
					}

					& > .group > .field,
					& > .field {

						&.fieldOf_emailNewsletter {
							background-color: #B10000;
							color: #F5F5F5;
							padding: 12px;
							box-sizing: content-box;
							margin: 28px 0 36px -12px;
							border-radius: 2px;
						}

						&.fieldOf_websiteBusiness {
							margin-bottom: 48px;
						}
					}
				}
			}
		}

		&:not(.treatAsContact){
			.cols {
				height: auto;
				min-height: 100%;

				.col {
					padding: 1em;
					height: auto;
					min-height: 100%;

					&:first-child {
						border-right: 1px solid #CDCDCD;
					}
				}
			}
		}
	}
}

.container_base_form_form.container_templates_artCrm_address_propertiesForm .window {
	width: 70%;
	height: 85%;
	top: 5%;
	left: 15%;
}