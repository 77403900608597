@import "~baseVariables";
@import "~masterVariables";
.templates_nav_logo {
  display: flex;
  width: 100%;
  flex-direction: row;

  & > img {
    width: 35px;
    padding: 0 10px;
  }

  & > .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 201px;

    & > .version {
      font-weight: 600;
    }

    & > .lastUpdate {
      font-size: 12px;
    }
  }

  & > .projectName {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 22px;
    color: #FFF;
  }
}