@import "~baseVariables";
@import "~masterVariables";
.templates_treesManagement_actionsForm {
  display: none;

  h1 {
    font-size: 1rem;
  }

  button, button.negative {
    width: 100%;
    float: left;
    clear: left;
    margin: 0 0 0.4em 10px;
    background-color: transparent !important;
    border: none;
    color: #572C00 !important;
    text-align: left;
    font-size: .875rem;
    padding-left:0;

    &:hover {
      color: #000000;
      background-color: transparent;
    }
  }
}
