@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_subscribers_importerUploader {

	padding: 2em 1em;

	button.upload {
		width: auto;
	}
}
