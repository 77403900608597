@import "~baseVariables";
@import "~masterVariables";
.base_tabStrip_tabStrip.templates_artCrm_activity_tabStrip_tabStrip {

	section {

		&.tabs {
			padding: 0;
			border-bottom: 2px solid @primaryColor;
		}

		.tabContent  {
			background-color: #ffffff;
			border-top: 0;
		}
	}
}