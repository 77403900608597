@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_edit_stats_info {
	font-size: 14px;
	box-sizing: border-box;
	padding: 2em 3em;

	&.hidden {
		display: none;
	}

	& > .title {
		font-weight: bold;
	}

	& > p {
		font-style: italic;
	}
}