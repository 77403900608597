@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_segments_controller {
	height: 100%;
	display: flex;
	flex-direction: column;

	section.filter {
		width: 100%;
		margin: 0;
		float: none;
	}
	.wrapper {
		display: flex;
		flex-direction: row;
		//height: 100%;
		height: ~"calc(100% - 41px)";

		section.list {
			display: flex;
			flex-direction: column;
			height: 100%;
			float: none;
			width: 45%;
		}
		section.edit {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
			height: 100%;
			width: 55%;
			float: none;
			padding: 2em;
			box-sizing: border-box;
			overflow: auto;
		}
	}
}