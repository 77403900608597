@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_segments_list {
	display: flex;
	flex-direction: column;
	padding: 2em;
	background-color: #FFF;
	height: 100%;
	border-right: 1px solid #CCCCCC;
	overflow: hidden;
	overflow-y: auto;
	box-sizing: border-box;

	button {
		align-self: center;
		margin-bottom: -40px;
		cursor: pointer;
		color: #FFFFFF;
		background-color: darkgreen;
		border: 1px solid darkgreen;
		margin-left: auto;
		font-size: .875rem;
		padding: 7px 10px;
		//font-family: "Open Sans", Arial, sans-serif;
		border-radius: 3px;
		z-index: 1;

		i {
			margin-right: 3px;
		}

		&:hover {
			background-color: #FFF;
			color: darkgreen;
		}
	}

	table {
		width: 100%;

		thead {
			th {
				color: #333;
				font-size: 16px;
				font-weight: bold;
				vertical-align: bottom;
				padding: 10px 10px;
				text-align: left;
				border-bottom: 3px solid #EBEBEB;

				&.edit {
					text-align: center;
					//background-color: #F1F0EF;
					width: 40px;
					padding: 10px 0;
				}

				i {
					margin-right: 5px;
				}
			}
		}

		tbody {
			tr {

				&:hover {
					background-color: #FFFFF2;
				}

				td {
					border-bottom: 1px solid #EBEBEB;
					padding: 15px 10px;
					vertical-align: middle;
					font-size: 14px;

					& > .name {
						display: inline-flex;
						flex-direction: column;

						& > .lastSegmentUpdateTS {
							font-size: .75em;
							margin-top: .5em;
						}
					}

					strong {
						text-transform: uppercase;
						//font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
						font-size: 11px;
						color: #999;
					}

					i {
						color: #888;
						margin-right: 5px;
					}

					&.edit, &.delete, &.refresh {
						text-align: center;
						padding: 15px 0;
						cursor: pointer;
						color: @primaryColor;
						font-size: 18px;

						& > i {
							color: inherit;
						}
					}
					&.delete {
						i { color: #FF0000; }
					}
					&.edit {
						i { color: darkgreen; }
					}
				}

				&:last-child {
					td {
						border-bottom: 3px solid #EBEBEB;
					}
				}
			}
		}

		tfoot {
			tr {
				td {
					padding-top: 5px;
				}
			}
		}
	}
}