@import "~baseVariables";
@import "~masterVariables";
.base_form_form .templates_newsletter_segments_data_data .cols .col .base_form_elements_field.field > *:not(.fieldIndependent).templates_newsletter_segments_data_fields_segmentationFieldIcon {
	display: inline-flex;
	align-items: center;
	flex-direction: row;
	width: auto !important;
	height: 100%;

	margin: 0 .625rem;
	font-size: 16px;
}