@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_newsletter_segments_data_value {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-left: 10px;
	width: 100%;

	& > * {
		display: flex;
		flex-direction: column;

		.col {
			flex-direction: column !important;
		}
	}
}