@import "~baseVariables";
@import "~masterVariables";
.templates_routeTrees_languageConnector {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5em;
  box-sizing: border-box;
  --language-sub-level: 0;

  & > section.description {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 1.5em;
    margin-bottom: 1.5em;
    box-sizing: border-box;
    border-bottom: 1px solid #CCCCCC;

    &.hidden {
      display: none;
    }

    & > .symbol {
      width: 1em;
      height: 1em;
      font-size: 2em;
      margin-right: 1em;
      color: #666666;
    }

    & > .content {
      width: 100%;
      display: flex;
      align-items: center;

      & i {
        font-size: 1.5em;
        margin: 0 .5em;
      }
    }

    &.warning {
      & > .symbol {
        color: #FF8800;
      }
    }

    &.error {
      & > .symbol {
        color: #B20000;
      }
    }
  }

  & > section.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    //padding: 1.5em;
    //box-sizing: border-box;
    --language-sub-level: 0;

    & > .language {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 0.75em;
      justify-content: flex-start;
      align-items: center;

      & > * {
        display: flex;
        flex-direction: row;
        margin-right: .5em;
        height: 100%;

        &.buttons {
          width: 320px;

          & > * {
            margin: 0 0 0 .5em;
          }

          & > *:first-child {
            margin-left: 0;
          }

          & > .base_form_fields_route-picker-button > button {
            margin: 0;
          }
        }

        &.flag {
          width: 30px;
          margin-right: 1em;
          margin-left: ~"calc(var(--language-sub-level, 0) * 2em + .5em)";

          & > i {
            font-size: 2em;
          }
        }

        &.routeDetails {
          flex-direction: column;
          width: ~"calc(100% - 350px - 1.5em - (var(--language-sub-level, 0) * 2em + .5em))";
          margin-right: 0;

          & > div {
            font-size: .875em;
            white-space: nowrap;
            overflow: hidden;

            &.path {
              font-size: .75em;
              font-style: italic;
            }
          }
        }
      }

      &.isDirty {
        background-color: lightblue;
      }

      &.secondaryConnection {
        margin-top: -0.5em;
      }
    }

    & > .separator {
      width: 100%;
      border-bottom: 1px solid #CCCCCC;
      margin: 10px 0 20px 0;
    }
  }
}

.container_templates_routeTrees_languageConnector {
  & > .window {
    width: 1000px;
    height: 600px;
    left: ~"calc(50% - 500px)";
    top: 150px;
  }
}
