@import "~baseVariables";
@import "~masterVariables";
.base_form_form .cols .col .field > *:not(.fieldIndependent).templates_ttgShop_orders_fields_basket,
.templates_ttgShop_orders_fields_basket {
	float: left;
	width: auto !important;
	height: 100%;
	overflow: auto;

	& > table {
		width: 100%;
		border-spacing: 0;
		table-layout: fixed;
		font-size: 13px;

		& > thead,
		& > tbody {

			& > tr {

				& > td {
					border: 1px solid @table_border_color;
					border-width: 0 0 1px 1px;

					&:last-child {
						border-right-width: 1px;
					}
				}
			}
		}

		& > thead {

			& > tr {

				&:first-child {

					& > td {
						border-top-width: 1px;
					}
				}
			}
		}

		& > thead {

			& > tr {
				background-color: @table_row_head_bg_color;
				color: @table_row_head_fg_color;
				text-transform: uppercase;

				&:first-child > td {
					padding-top: 5px;
				}
				&:last-child > td {
					padding-bottom: 5px;
				}

				& > td {
					background-color: @table_row_head_bg_color;
					padding: 3px 8px;
					font-size: 1em;
					white-space: nowrap;
				}
			}
		}

		& > tbody {
			overflow-y: auto;
			background-color: @table_row_bg_color;
			z-index: 1;

			&:nth-child(even) {
				background-color: @table_row_even_bg_color;
			}

			&:hover {
				background-color: @table_row_hover_bg_color;
				color:            @table_row_hover_fg_color;
				cursor: pointer;
			}

			& > tr {
				color: @table_row_fg_color;

				&:first-child > td {
					padding-top: 7px;
				}
				&:last-child > td {
					padding-bottom: 7px;
				}

				& > td {
					box-sizing: border-box;
					padding: 3px 8px;
					font-size: 1em;
					word-break: break-all;
					white-space: nowrap;

					& a {
						text-decoration: none;
						color: inherit;
					}

					&.ellipsis {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						word-break: keep-all;
						max-width: 0;
					}

					&.img {
						padding: 2px;
						text-align: center;

						& > img {
							height: 50px;
						}
					}

					&.actions {
						color: @primaryColor;
						text-align: right;

						& > * {
							margin-right: 5px;
						}
						& > *:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}
	}


	& > .emptyTableMessage {
		font-size: 1em;
		box-sizing: border-box;
		padding: 0.5em 0;
		margin: 0;
	}
}
