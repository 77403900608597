@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_mblx_products_propertiesForm {
  box-sizing: border-box;
  padding: 1em 1em 0 1em;
  max-width: 800px;

  &.absoluteRight {
    position: absolute;
    right: 0;
    min-width: 400px;
  }
}