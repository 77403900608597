@import "~baseVariables";
@import "~masterVariables";
.base_modal_modal.container_templates_newsletter_subscribers_importer .window {
	width: 50%;
	height: 50%;
	left: 30%;
	top: 25%;
}

.templates_newsletter_subscribers_importer {
	section.content {
		height: fit-content;
	}
	.uploadResult {
		padding: 0 1em;
		//font-family: "Open Sans", Arial, sans-serif;
		font-size: 14px;

		&.progress {
			color: #666;
		}
		&.error {
			color: #FF0000;
		}
		&.success {
			color: #0d6632;
		}
	}
}
