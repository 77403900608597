@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_mblx_products_additionalInformation_propertiesForm {
  box-sizing: border-box;
  padding: 1em;

  & > .cols > .col > .field {

    & > *:not(.fieldIndependent){

      & > textarea {
        height: 198px;
      }
    }
  }
}