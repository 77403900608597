@import "~baseVariables";
@import "~masterVariables";
.base_trees_trees.templates_bulk_layoutTrees.templates_bulk_chooseLayoutTrees {

  &.squared {

    & > section.filter {
      & > .templates_treesManagement_filter.isHorizontal {
        width: 100%;
        padding-right: 20px;
      }
    }

    & > section.filterContent > section.colWrap {
      flex-direction: column;
      width: 100%;

      & > section.treeContainer {
        height: unset;
        flex: 1;
      }

      & > section.properties {
        height: unset;
        flex: unset;
      }
    }
  }
}