@import "~baseVariables";
@import "~masterVariables";
@labelWidth: 120px;

.base_form_form.templates_protocol_stats_filter {

	& > .cols {

		& > .col {

			/* FIELDS AND GROUP'S FIELDS */
			& > .group > .field,
			& > .field {

				& > *:not(.fieldIndependent) {
					width: ~"calc(100% - @{labelWidth})" !important;
				}

				& > label:not(.fieldIndependent) {
					width: @labelWidth !important;
					flex: 1 0 auto;
				}

				& > .base_form_fields_tag-picker-button .base_form_fields_toggle,
				& > .base_form_fields_toggle {
					--toggle-horizontal-padding: 12px;
				}
			}

			& > .field {

				& > .base_form_fields_tag-picker-button {
					width: 100% !important;
				}
			}
		}
	}

	&.isHorizontal {
		box-sizing: border-box;

		label {
			display: none !important;
		}

		.cols {

			.col {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 0;

				.base_form_elements_field {
					margin-bottom: 0;

					&.field > *:not(.fieldIndependent) {
						width: 100% !important;
					}

					& > .base_tooltip_tooltip {
						position: absolute;
					}
				}

				.buttons {
					width: auto;
					margin: 0 8px 0 0;

					button {
						float: unset;
						display: flex;
						flex-wrap: nowrap;
						white-space: nowrap;
						margin-left: 0;

						i {
							margin-right: 6px;
						}
					}
				}
			}
		}
	}
}
