@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_edit_segments_segments {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	padding: 1em 2em;
	background-color: #FFF;
	height: 100%;
	box-sizing: border-box;

	.segmentSelection {
		padding-bottom: 20px;
		border-bottom: 1px dashed #CCC;

		& > div {
			display: flex;
		}

		select {
			padding: 10px 9px;
			font-size: 16px;
			border: 1px solid #CCCCCC;
			min-width: 540px;
			margin-right: 3px;

			option {
				height: 30px
			}
		}
		button {
			cursor: pointer;
			padding: 11px 20px;
			font-size: 16px;
			color: #FFF;
			border: 1px solid darkgreen;
			background-color: green;
			margin-right: 10px;
			i {
				margin-right: 3px;
			}
		}

		.assignedRecipientsCount {
			padding: 8px 12px;
			background-color: #fff3d2;
			border: 1px solid @primaryColor;
			color: @primaryColor;
			margin-left: 40px;
			display: flex;
			align-items: center;
			width: fit-content;

			.title {
				text-transform: uppercase;
				//font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
				font-size: 13px;
				margin-right: 20px;
			}
			span {
				font-size: 18px;
			}
			i {
				margin-left: 10px;
				cursor: pointer;
			}
		}
	}

	.assignedMailingSegments {

		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		overflow: auto;
		margin-bottom: 1em;
		box-sizing: border-box;

		.mailingSegment {
			padding: 15px 20px;
			background-color: #F9F9F9;
			border: 1px solid #E6E5E3;
			margin-right: 20px;
			margin-top: 20px;
			min-height: 185px;

			&.importedForMailing {
				border: 1px solid @primaryColor;

				&.hidden {
					display: none;
				}
			}

			& > div {
				display: flex;
				flex-direction: column;
			}

			.title {
				text-transform: uppercase;
				//font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
				font-size: 11px;
				color: #999;
				margin-bottom: 4px;
			}
			.segmentName, .mailinglistName, .subscriptionCount {
				//font-family: "Open Sans", Arial, Helvetica, sans-serif;
				font-size: 16px;
				color: #000000;
				margin-bottom: 20px;
			}
			.subscriptionCount {
				font-size: 18px;
				&.no-recipients {
					color: red;
				}
				i {
					margin-right: .25em;
					cursor: pointer;
				}

				.lastSegmentUpdateTS {
					font-size: .75rem;
					margin-top: -.25em;
					margin-bottom: .5em;
				}
			}
			.removeMailingSegment,
			.showEdit_mailingSubscribers {
				display: flex;
				//font-family: "Open Sans", Arial, Helvetica, sans-serif;
				font-size: 14px;
				color: #B20000;
				margin-top: 10px;
				cursor: pointer;

				i {
					width: 1rem;
					margin-right: .5rem;
					font-size: 1rem;
					text-align: center;
				}
			}

			.showEdit_mailingSubscribers {
				color: #000000;
			}
		}
	}
}