@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_propertiesForms_routePropertiesForm {
	margin: 1em;
	max-width: 800px;

	& > .cols {

		& > .col {

			& > .field.fieldOf_langCode {
				& > label.inline {
					float: right;
					font-size: 12px;
					margin: -3px 0 7px 0;
					font-style: italic;
					text-overflow: ellipsis;
					white-space: nowrap;
					word-break: break-all;
					width: 70% !important;
					overflow: hidden;

					&:hover {
						cursor: text;
					}
				}
			}
		}
	}
}

.base_modal_modal.container_base_form_form.container_templates_propertiesForms_routePropertiesForm {
	& > .window {
		width: 60%;
		height: 90%;
		top: 4%;
		max-width: 860px;
	}

	.base_form_form.templates_propertiesForms_routePropertiesForm {
		margin: 0;
		max-width: unset;
	}
}