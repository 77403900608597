@import "~baseVariables";
@import "~masterVariables";
.templates_mediaManagement_file_fileList {
	display: flex;
	flex-direction: column;

	& > .fileList {
		width: 100%;
	}
	& > .newFiles {
		display: flex;
		flex-direction: column;
		width: 100%;

		& > .title {
			font-size: 1em;
			border-bottom: 1px solid #808080;
			margin-bottom: 12px;
			padding: 0 0 3px 12px;
			font-style: italic;
		}

		& > .newFileList {
			width: 100%;
			margin-bottom: 12px;
			padding: 0 25px 12px 25px;
			box-sizing: border-box;
			border-bottom: 1px solid #808080;
		}

		& > * {
			display: none;
		}

		&.active > * {
			display: block;
		}
	}


	& > .buttons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding-top: .75em;

		& > button {
			font-size: 1.125em;
		}

		& > button > i {
			font-size: 1.125em;
			margin-right: .25em;
		}
	}
}