@import "~baseVariables";
@import "~masterVariables";
.layouts_design_dummy {

  & > section.header {
    width: 100%;
    height: 10%;
  }

  & > section.left {
    width: ~"calc(100% - 250px)";
    height: 80%;
  }

  & > section.right {
    width: 250px;
    height: 80%;
  }

  & > section.footer {
    width: 100%;
    height: 10%;
  }
}

