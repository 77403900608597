@import "~baseVariables";
@import "~masterVariables";
.templates_artCrm_trip_fields_addressSelector {
	display: flex;
	flex-direction: row;
	align-items: center;

	& > div {
		width: 100%;
	}

	& > section.buttons {
		margin-left: auto;
		display: flex;
		flex-direction: row;
	}

	&.unspecified {

		& > div {
			font-style: italic;
		}
	}
}