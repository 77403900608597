@import "~baseVariables";
@import "~masterVariables";
.base_form_media_image_image.templates_mediaManagement_artImageArchive_baseFormMediaImageImage {
	align-items: flex-start;
	flex-direction: column;
	width: 305px;
	height: 290px;
	padding-bottom: 0;

	& > .controls {
		margin-left: 300px;
	}

	& > img {
		max-width: 295px;
		max-height: 280px;
	}

	& > i {
		font-size: 7rem;
		margin: 2rem auto;
	}
}