@import "~baseVariables";
@import "~masterVariables";
.container_templates_login_container .window {
  width: 40%;
  height: 40%;
  left: 30%;
  top: 15%;

  section.body {
    .buttons {
      display: none;
    }
  }
}