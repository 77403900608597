@import "~baseVariables";
@import "~masterVariables";
.templates_artCrm_address_details {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 1em;
	box-sizing: border-box;

	& > textarea {
		font-size: 1.25em;
		padding: .5em;
		width: 100%;
		height: 100%;
	}
}

.container_templates_artCrm_address_details .window {
	width: 600px;
	height: 400px;
	top: 100px;
	left: ~"calc(50% - 300px)";
}