@import "~baseVariables";
@import "~masterVariables";
////////////////////////////////////////////////
// CMS
////////////////////////////////////////////////
html,
body {
	height: 100%;
}

html,
body,
button,
input,
optgroup,
select,
textarea {
	font-family: @baseFontFamily;
}

.appContainer {
	min-width: 1024px;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	& > section {
		display: flex;
		flex-direction: row;

		&.status {
			width: 0;
			height: 0;
		}

		&.top {
			background-color: @primaryColor;
			flex: 0 0 50px;
			color: #FFF;

			& > .templates_nav_nav {
				margin-left: auto;
			}
		}

		&.content {
			width: 100%;
			height: 100%;
			overflow: hidden;

			& > section {

				&.main {
					display: flex;
					justify-content: flex-start;
					align-items: stretch;
					width: auto;
					flex: auto;
					height: 100%;
					color: #000;
					background-color: #E7E6E4;
					overflow: hidden;
				}

				&.login {
					background-color: #E7E6E4;
					position: absolute;
					top: 0;
					left: 0;
					width:100%;
					height:100%;
				}
			}
		}

	}
}

// TODO: POIAAA
//body.breakPoint-l,
//body.breakPoint-m {
//
//	//.appContainer {
//	//	flex-direction: column;
//	//}
//
//	section {
//
//		//&.mainmenu {
//		//	width: 100%;
//		//	height: 42px;
//		//	background-color: #575757;
//		//	z-index: 2;
//		//	display: block;
//		//
//		//	section.head {
//		//		width: 180px;
//		//
//		//		.templates_nav_logo {
//		//			border: none;
//		//			margin: 0;
//		//		}
//		//	}
//		//
//		//	section.menu {
//		//		width: ~"calc(100% - 180px)";
//		//		//height: 100%;
//		//
//		//		& > ul {
//		//			height: inherit;
//		//
//		//			& > li {
//		//				height: inherit;
//		//				display: inline-block;
//		//
//		//				ul {
//		//					display: none;
//		//					position: fixed;
//		//					width: auto;
//		//					z-index: 2;
//		//
//		//					& > li {
//		//						display: block;
//		//						width: inherit;
//		//						//height: 100%;
//		//
//		//						& > a {
//		//							padding-left: 10px;
//		//							padding-right: 15px;
//		//
//		//							& > i {
//		//								float: left;
//		//								margin-left: 17px;
//		//								padding-right: 0;
//		//							}
//		//						}
//		//					}
//		//				}
//		//
//		//				& > a {
//		//					padding-left: 6px;
//		//					line-height: 42px;
//		//					padding-right: 20px;
//		//
//		//					i {
//		//						float: left;
//		//						padding-left: 10px;
//		//						line-height: 42px;
//		//						padding-right: 0;
//		//					}
//		//				}
//		//			}
//		//
//		//			li:hover > ul {
//		//				display: block;
//		//			}
//		//		}
//		//	}
//		//}
//
//		&.main {
//			width: 100%;
//		}
//	}
//}

[data-component-index]:not(.customLoadingAnimation) {
	opacity: 1;
	-webkit-transition: opacity .1s ease-in-out;
	-moz-transition: opacity .1s ease-in-out;
	-ms-transition: opacity .1s ease-in-out;
	-o-transition: opacity .1s ease-in-out;
	transition: opacity .1s ease-in-out;

	&.loading {
		opacity: 0;
	}

	&.refreshLoading {

		&, & [data-component-index] {
			opacity: 1;
		}
	}
}

////////////////////////////////////////////////
// BASE COMPONENT STYLING
////////////////////////////////////////////////

@import "master-basestyles";
