@import "~baseVariables";
@import "~masterVariables";
.base_tabStrip_tabContent .templates_propertiesForms_newsPropertiesForm {
  margin: 1em;
  max-width: 800px;

  & > .cols {

    & > .col {

      & > .field.fieldOf_langCode {
        & > label.inline {
          float: right;
          font-size: 12px;
          margin: -3px 0 7px 0;
          font-style: italic;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
          width: 70% !important;
          overflow: hidden;

          &:hover {
            cursor: text;
          }
        }
      }
    }
  }
}
