@import "~baseVariables";
@import "~masterVariables";
.templates_artCrm_connectionsTable_connectionsTable {
	height: auto !important;
	overflow: auto;

	& > section.head {
		border-bottom: 1px solid #9A9A9A;
		align-items: center;
		padding: 0;
		margin: 0 0 .5em 0;

		& > h2 {
			font-size: 1.325rem;
			padding: .5rem 0;
			margin: 0;
			text-transform: uppercase;
			color: #666;
		}
	}

	& > table {

		& > thead,
		& > tbody {
			& > tr {
				& > td {
					&.emptyTableMessage {
						font-size: 1.125rem;
					}
				}
			}
		}

		& > tbody {
			max-height: 100%;
			overflow: auto;
		}
	}
}