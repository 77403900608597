@import "~baseVariables";
@import "~masterVariables";
.templates_mblx_reports_uploader {

    & > button.upload.filesChosen {
        background-color: #70a970;
        color: #333333;
        border: 1px solid #006600;
    }

    & > .progress {
        margin-top: 10px !important;
    }
}