@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_profileControls_serviceCollectionOptionsForm,
.templates_profileControls_serviceCollectionOptionsForm {
	padding: 9px 20px 0 20px;
	box-sizing: border-box;
	margin-left: auto;

	.cols .col .field > *:not(.fieldIndependent) {
		float: unset;
		width: unset !important;
	}
	.cols .col .field > label {
		margin-right: 15px;
	}
}