@import "~baseVariables";
@import "~masterVariables";
.base_form_form .templates_newsletter_segments_data_data {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 5px;
	opacity: 1;

	&.isLoading {
		display: none;
		opacity: 0;
	}

	&.isReadyToDelete {
		opacity: .5;
	}

	.cols {
		display: flex;
		flex-direction: row;

		.col {
			display: flex;
			flex-direction: row;
			flex-grow: 1;

			.base_form_elements_field {
				display: flex;
				flex-direction: column;
				margin-right: 10px;
				justify-content: flex-end;

				&.hidden {
					display: none;
				}

				&.field > *:not(.fieldIndependent) {
					width: 100% !important;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	& > .cols {
		width: 150%;

		& > .col {

			& > .base_form_elements_field {

				&:first-child {
					flex-direction: row;
					align-items: flex-end;

					&.validationFailed {
						flex-direction: column;
					}
				}
			}
		}
	}

	button.base_form_elements_buttons_default[data-field-type="delete"] {
		margin: 0 0 10px 10px;
		padding: 7px 8px;

		i {
			margin: 0;
		}
	}
}

