@import "~baseVariables";
@import "~masterVariables";
.base_tabStrip_tab.templates_artCrm_activity_tabStrip_tab {
	display: flex;
	flex-direction: column;

	&.item {
		cursor: pointer;
		float: left;
		vertical-align: middle;
		text-decoration: none;
		background: none;
		border-bottom: 10px solid #CDCDCD;
		border-width: 0 0 10px 0;
		border-radius: 0;
		color: @primaryColor;
		margin-bottom: -1px;
		margin-left: 0;
		padding: 0;
		z-index: 1;

		&.closable {
			padding-right: 10px;
		}

		&:first-child {
			margin-left: 0;
		}

		&:hover {
			color: #333;
		}

		&.active {
			color: @primaryColor;
			font-weight: normal;
			border-bottom: 11px solid @primaryColor;
		}

		& > section.content {
			display: flex;
			flex-direction: column;
			padding: 15px 30px 20px 30px;
			border-right: 1px solid #CDCDCD;

			i {
				cursor:pointer;
			}

			button {
				float: right;
				background: none;
				border: none;
				margin: -5px -8px 0 5px;
				color: #333;
				line-height: inherit;

				&:hover {
					color: #CCCCCC;
					cursor: pointer;
				}
			}
			.tab-icon {
				margin-right: 3px;
			}

			& > div.title {
				padding-bottom: 5px;
				font-weight: 600;

				i {
					margin-left: 10px;
				}
			}
			& > div.subTitle {
				//font-family: Arial, Helvetica, sans-serif;
				font-size: 13px;
				color: #666;
			}

			i.statusSymbol {
				margin-left: -12px;
				top: 3px;

				&.validationFailedSymbol {
					color: #B20000;
				}
			}
		}
	}
}