@import '../node_modules/frontend-controller/styles/icons/vendor/font-awesome-6.2.0/css/regular.css';
@import '../node_modules/frontend-controller/styles/icons/vendor/font-awesome-6.2.0/css/solid.css';
@import '../node_modules/frontend-controller/styles/icons/vendor/font-awesome-6.2.0/css/brands.css';
@import '../node_modules/frontend-controller/styles/icons/vendor/font-awesome-6.2.0/css/brands.css';
@import '../node_modules/frontend-controller/styles/icons/vendor/font-awesome-6.2.0/css/regular.css';
@import '../node_modules/frontend-controller/styles/icons/vendor/font-awesome-6.2.0/css/solid.css';
.fi.fi-arrow-left-stop {
  border-left: 0.125em solid;
}
.fi.fi-arrow-right-stop {
  border-right: 0.125em solid;
}
.fi.fi-arrow-up-stop {
  border-top: 0.125em solid;
}
.fi.fi-arrow-down-stop {
  border-bottom: 0.125em solid;
}
.fi.fi-categories {
  font-weight: 400 !important;
}
.fi.fi-picture-o {
  font-weight: 400 !important;
}
.fi.fi-livecom-jobs {
  font-weight: 900 !important;
}
.fi.fi-livecom-members {
  font-weight: 900 !important;
}
.fi.fi-sms {
  font-weight: 900 !important;
}
/* open-sans-regular - greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.woff') format('woff'), /* Modern Browsers */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
}
/* open-sans-600 - greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.woff2') format('woff2'), /* Super Modern Browsers */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.woff') format('woff'), /* Modern Browsers */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.svg#OpenSans') format('svg');
  /* Legacy iOS */
}
/* open-sans-700 - greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.woff2') format('woff2'), /* Super Modern Browsers */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.woff') format('woff'), /* Modern Browsers */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('../node_modules/frontend-controller/styles/fonts/sources/opensans/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.svg#OpenSans') format('svg');
  /* Legacy iOS */
}
.fi {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");font-weight: var(--fa-style, 900);
}
.fi,
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-trash,
.fi.fi-circle-o,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-check-double,
.fi.fi-copy,
.fi.fi-copy-to-clipboard,
.fi.fi-circle-question,
.fi.fi-save,
.fi.fi-error,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-info,
.fi.fi-tooltip,
.fi.fi-list,
.fi.fi-send-confirmation,
.fi.fi-confirm-mail,
.fi.fi-confirm-mobile,
.fi.fi-no-change,
.fi.fi-ban,
.fi.fi-password-input,
.fi.fi-play,
.fi.fi-pause,
.fi.fi-tel,
.fi.fi-mobile,
.fi.fi-email,
.fi.fi-open,
.fi.fi-open-location,
.fi.fi-heartbeat-connection,
.fi.fi-resetPassword,
.fi.fi-generatePasswordOfLength,
.fi.fi-link,
.fi.fi-tagFamily-unselected,
.fi.fi-tagFamily-selected,
.fi.fi-tag-unselected,
.fi.fi-tag-selected,
.fi.fi-category,
.fi.fi-categoryData,
.fi.fi-categoryData-default,
.fi.fi-setDefault-categoryData,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-user-circle,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-location-options,
.fi.fi-livecom-jobs,
.fi.fi-livecom-members,
.fi.fi-media-placeholder,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-sms,
.fi.fi-participant,
.fi.fi-cut,
.fi.fi-paste,
.fi.fi-flag,
.fi.fi-reset,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-layouts,
.fi.fi-component,
.fi.fi-form,
.fi.fi-layout,
.fi.fi-route,
.fi.fi-routeFallback,
.fi.fi-open-routes,
.fi.fi-banner,
.fi.fi-events,
.fi.fi-show-select-columns,
.fi.fi-clock,
.fi.fi-copy,
.fi.fi-check-square,
.fi.fi-envelope,
.fi.fi-envelope-o,
.fi.fi-user,
.fi.fi-user-o,
.fi.fi-user-plus,
.fi.fi-scope,
.fi.fi-scopes,
.fi.fi-scopeRole,
.fi.fi-scopeRoles,
.fi.fi-section,
.fi.fi-section-bulk,
.fi.fi-times-circle,
.fi.fi-minus-circle,
.fi.fi-file-text,
.fi.fi-paperclip,
.fi.fi-search,
.fi.fi-globals,
.fi.fi-userSessions,
.fi.fi-mailinglist,
.fi.fi-subscriber,
.fi.fi-user-edit,
.fi.fi-segment,
.fi.fi-resend,
.fi.fi-address,
.fi.fi-addresses,
.fi.fi-address-type,
.fi.fi-address-type-client,
.fi.fi-address-type-journalist,
.fi.fi-address-type-media,
.fi.fi-address-type-contact,
.fi.fi-trip,
.fi.fi-cooperation,
.fi.fi-cooperations,
.fi.fi-address-book,
.fi.fi-mailingUrl-viewSubscribers,
.fi.fi-shop,
.fi.fi-shop-product,
.fi.fi-shop-order,
.fi.fi-shop-coupon,
.fi.fi-shop-user,
.fi.fi-euro,
.fi.fi-clock,
.fi.fi-reports,
.fi.fi-robot,
.fi.fi-protocol,
.fi.fi-protocol-protocol {
  -moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;display: var(--fa-display, inline-block);font-style: normal;font-variant: normal;line-height: 1;text-rendering: auto;
}
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-trash,
.fi.fi-circle-o,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-check-double,
.fi.fi-copy,
.fi.fi-copy-to-clipboard,
.fi.fi-circle-question,
.fi.fi-save,
.fi.fi-error,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-info,
.fi.fi-tooltip,
.fi.fi-list,
.fi.fi-send-confirmation,
.fi.fi-confirm-mail,
.fi.fi-confirm-mobile,
.fi.fi-no-change,
.fi.fi-ban,
.fi.fi-password-input,
.fi.fi-play,
.fi.fi-pause,
.fi.fi-tel,
.fi.fi-mobile,
.fi.fi-email,
.fi.fi-open,
.fi.fi-open-location,
.fi.fi-heartbeat-connection,
.fi.fi-resetPassword,
.fi.fi-generatePasswordOfLength,
.fi.fi-link,
.fi.fi-tagFamily-unselected,
.fi.fi-tagFamily-selected,
.fi.fi-tag-unselected,
.fi.fi-tag-selected,
.fi.fi-category,
.fi.fi-categoryData,
.fi.fi-categoryData-default,
.fi.fi-setDefault-categoryData,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-user-circle,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-location-options,
.fi.fi-livecom-jobs,
.fi.fi-livecom-members,
.fi.fi-media-placeholder,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-sms,
.fi.fi-participant,
.fi.fi-cut,
.fi.fi-paste,
.fi.fi-flag,
.fi.fi-reset,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-layouts,
.fi.fi-component,
.fi.fi-form,
.fi.fi-layout,
.fi.fi-route,
.fi.fi-routeFallback,
.fi.fi-open-routes,
.fi.fi-banner,
.fi.fi-events,
.fi.fi-show-select-columns,
.fi.fi-clock,
.fi.fi-copy,
.fi.fi-check-square,
.fi.fi-envelope,
.fi.fi-envelope-o,
.fi.fi-user,
.fi.fi-user-o,
.fi.fi-user-plus,
.fi.fi-scopeRole,
.fi.fi-scopeRoles,
.fi.fi-section,
.fi.fi-section-bulk,
.fi.fi-times-circle,
.fi.fi-minus-circle,
.fi.fi-file-text,
.fi.fi-paperclip,
.fi.fi-search,
.fi.fi-globals,
.fi.fi-userSessions,
.fi.fi-mailinglist,
.fi.fi-subscriber,
.fi.fi-user-edit,
.fi.fi-segment,
.fi.fi-resend,
.fi.fi-address,
.fi.fi-addresses,
.fi.fi-address-type,
.fi.fi-address-type-client,
.fi.fi-address-type-journalist,
.fi.fi-address-type-media,
.fi.fi-address-type-contact,
.fi.fi-trip,
.fi.fi-cooperation,
.fi.fi-cooperations,
.fi.fi-address-book,
.fi.fi-mailingUrl-viewSubscribers,
.fi.fi-shop,
.fi.fi-shop-product,
.fi.fi-shop-order,
.fi.fi-shop-coupon,
.fi.fi-shop-user,
.fi.fi-euro,
.fi.fi-clock,
.fi.fi-reports,
.fi.fi-robot,
.fi.fi-protocol,
.fi.fi-protocol-protocol {
  font-family: 'Font Awesome 6 Free';
}
.fi.fi-scope,
.fi.fi-scopes {
  font-family: 'Font Awesome 6 Brands';
}
.fi.fi-trash::before {
  content: "\f2ed";
}
.fi.fi-file::before,
.fi.fi-file-o::before,
.fi.fi-file-text-o::before,
.fi.fi-media-txt::before,
.fi.fi-component::before,
.fi.fi-shadowComponent::before,
.fi.fi-route::before,
.fi.fi-routeFallback::before,
.fi.fi-file-text::before {
  content: "\f15c";
}
.fi.fi-calendar::before,
.fi.fi-newsletter-segment-segmentData-fieldType-date::before {
  content: "\f073";
}
.fi.fi-sort-desc::before {
  content: "\f0dd";
}
.fi.fi-minus-circle::before,
.fi.fi-minus-circle::before {
  content: "\f056";
}
.fi.fi-sign-out::before {
  content: "\f2f5";
}
.fi.fi-address-type-contact::before,
.fi.fi-address-book::before {
  content: "\f2b9";
}
.fi.fi-edit-slim::before {
  content: "\f303";
}
.fi.fi-collapsed::before,
.fi.fi-caret-right::before {
  content: "\f0da";
}
.fi.fi-copy-to-clipboard::before,
.fi.fi-paste::before {
  content: "\f0ea";
}
.fi.fi-categoryData-default::before,
.fi.fi-setDefault-categoryData::before {
  content: "\f1ce";
}
.fi.fi-globals::before {
  content: "\f1e5";
}
.fi.fi-cut::before {
  content: "\f0c4";
}
.fi.fi-minus-square::before {
  content: "\f146";
}
.fi.fi-list::before {
  content: "\f0c9";
}
.fi.fi-caret-left::before {
  content: "\f0d9";
}
.fi.fi-exclamation-circle::before,
.fi.fi-error::before {
  content: "\f06a";
}
.fi.fi-routeTree::before {
  content: "\f0e8";
}
.fi.fi-shop-coupon::before {
  content: "\f4b9";
}
.fi.fi-flag::before {
  content: "\f024";
}
.fi.fi-selectAll-media-image::before,
.fi.fi-selectAll-media-file::before,
.fi.fi-selectAll-component::before,
.fi.fi-selectAll-category::before,
.fi.fi-check-square::before {
  content: "\f14a";
}
.fi.fi-newsletter-segment-segmentData-fieldType-list::before,
.fi.fi-section-bulk::before,
.fi.fi-protocol-protocol::before {
  content: "\f03a";
}
.fi.fi-lock::before,
.fi.fi-privilege::before,
.fi.fi-privileges::before {
  content: "\f023";
}
.fi.fi-edit::before {
  content: "\f044";
}
.fi.fi-share-alt::before,
.fi.fi-assign::before,
.fi.fi-connect-language::before,
.fi.fi-connect-layout::before,
.fi.fi-select::before,
.fi.fi-assign-tags::before,
.fi.fi-upload-tagged::before {
  content: "\f1e0";
}
.fi.fi-users::before,
.fi.fi-address-type-media::before {
  content: "\f0c0";
}
.fi.fi-angle-right::before {
  content: "\f105";
}
.fi.fi-folder::before {
  content: "\f07b";
}
.fi.fi-user::before,
.fi.fi-user-o::before,
.fi.fi-user::before,
.fi.fi-user-o::before,
.fi.fi-scopeRole::before,
.fi.fi-scopeRoles::before,
.fi.fi-subscriber::before,
.fi.fi-address-type-client::before,
.fi.fi-mailingUrl-viewSubscribers::before {
  content: "\f007";
}
.fi.fi-resetPassword::before,
.fi.fi-generatePasswordOfLength::before {
  content: "\f084";
}
.fi.fi-website::before {
  content: "\f0ac";
}
.fi.fi-art-download-stats::before,
.fi.fi-activity::before,
.fi.fi-activities::before,
.fi.fi-protocol-stats::before {
  content: "\f1fe";
}
.fi.fi-no-change::before,
.fi.fi-ban::before,
.fi.fi-reset::before {
  content: "\f05e";
}
.fi.fi-image-new::before,
.fi.fi-file-new::before {
  content: "\f005";
}
.fi.fi-move::before {
  content: "\f074";
}
.fi.fi-sign-in::before,
.fi.fi-decisionComponent-signIn::before,
.fi.fi-signIn-submit::before {
  content: "\f2f6";
}
.fi.fi-arrow-circle-up::before {
  content: "\f0aa";
}
.fi.fi-heartbeat-connection::before {
  content: "\f1eb";
}
.fi.fi-user-edit::before {
  content: "\f4ff";
}
.fi.fi-coffee::before {
  content: "\f7b6";
}
.fi.fi-activities-lobbying::before {
  content: "\f530";
}
.fi.fi-window-restore::before,
.fi.fi-layouts::before {
  content: "\f2d2";
}
.fi.fi-plus-square::before {
  content: "\f0fe";
}
.fi.fi-picture-o::before,
.fi.fi-mediaImage::before,
.fi.fi-media-placeholder::before,
.fi.fi-banner::before {
  content: "\f03e";
}
.fi.fi-caret-up::before {
  content: "\f0d8";
}
.fi.fi-show-select-columns::before {
  content: "\f0db";
}
.fi.fi-angles-right::before {
  content: "\f101";
}
.fi.fi-euro::before {
  content: "\f153";
}
.fi.fi-check-circle::before,
.fi.fi-tagFamily-selected::before,
.fi.fi-tag-selected::before {
  content: "\f058";
}
.fi.fi-livecom-members::before,
.fi.fi-participant::before,
.fi.fi-address-type-journalist::before {
  content: "\f508";
}
.fi.fi-arrow-circle-down::before,
.fi.fi-lazyload::before,
.fi.fi-lazyload-images::before,
.fi.fi-lazyload-files::before {
  content: "\f0ab";
}
.fi.fi-pause::before,
.fi.fi-pause::before,
.fi.fi-pause-media-image::before {
  content: "\f04c";
}
.fi.fi-address::before,
.fi.fi-addresses::before,
.fi.fi-address-type::before {
  content: "\f2bb";
}
.fi.fi-arrow-circle-left::before {
  content: "\f0a8";
}
.fi.fi-media-doc::before,
.fi.fi-media-docx::before {
  content: "\f1c2";
}
.fi.fi-square-o::before,
.fi.fi-deselectAll-media-image::before,
.fi.fi-deselectAll-media-file::before,
.fi.fi-deselectAll-component::before,
.fi.fi-deselectAll-category::before,
.fi.fi-section::before {
  content: "\f0c8";
}
.fi.fi-news::before {
  content: "\f1ea";
}
.fi.fi-arrow-circle-right::before {
  content: "\f0a9";
}
.fi.fi-filter::before {
  content: "\f0b0";
}
.fi.fi-newsletter-segment-segmentData-fieldType-boolean::before {
  content: "\3f";
}
.fi.fi-full-screen::before,
.fi.fi-move-all::before {
  content: "\f0b2";
}
.fi.fi-tarantula::before {
  content: "\f121";
}
.fi.fi-leaf::before,
.fi.fi-atoms::before,
.fi.fi-atom::before {
  content: "\f06c";
}
.fi.fi-file-excel-o::before {
  content: "\f1c3";
}
.fi.fi-move-right::before {
  content: "\f35a";
}
.fi.fi-arrow-right::before,
.fi.fi-arrow-right-stop::before {
  content: "\f061";
}
.fi.fi-circle::before,
.fi.fi-circle-o::before,
.fi.fi-tagFamily-unselected::before,
.fi.fi-tag-unselected::before,
.fi.fi-category::before,
.fi.fi-categoryData::before {
  content: "\f111";
}
.fi.fi-move-up::before {
  content: "\f35b";
}
.fi.fi-file-image-o::before,
.fi.fi-media-svg::before,
.fi.fi-media-jpg::before,
.fi.fi-media-jpeg::before,
.fi.fi-media-png::before,
.fi.fi-media-gif::before {
  content: "\f1c5";
}
.fi.fi-circle-question::before {
  content: "\f059";
}
.fi.fi-trip::before,
.fi.fi-trips::before {
  content: "\f5b0";
}
.fi.fi-shop-order::before {
  content: "\f291";
}
.fi.fi-eye::before {
  content: "\f06e";
}
.fi.fi-save::before {
  content: "\f0c7";
}
.fi.fi-sort-asc::before {
  content: "\f0de";
}
.fi.fi-tel::before {
  content: "\f095";
}
.fi.fi-newsletter-segment-segmentData-fieldType-number::before {
  content: "\f163";
}
.fi.fi-components::before {
  content: "\f1b3";
}
.fi.fi-arrow-left::before,
.fi.fi-arrow-left-stop::before {
  content: "\f060";
}
.fi.fi-open::before,
.fi.fi-open-routes::before {
  content: "\f35d";
}
.fi.fi-file-pdf-o::before,
.fi.fi-media-pdf::before {
  content: "\f1c1";
}
.fi.fi-tag::before {
  content: "\f02b";
}
.fi.fi-confirm-mail::before,
.fi.fi-email::before,
.fi.fi-envelope::before,
.fi.fi-envelope-o::before,
.fi.fi-newsletter::before,
.fi.fi-activities-newsletter::before,
.fi.fi-activities-mail::before {
  content: "\f0e0";
}
.fi.fi-paperclip::before {
  content: "\f0c6";
}
.fi.fi-info::before,
.fi.fi-tooltip::before {
  content: "\f05a";
}
.fi.fi-mediaImageDetails::before,
.fi.fi-mediaFileDetails::before {
  content: "\f05a";
}
.fi.fi-events::before {
  content: "\f274";
}
.fi.fi-check-double::before {
  content: "\f560";
}
.fi.fi-list-alt::before,
.fi.fi-categories::before,
.fi.fi-form::before,
.fi.fi-mailinglist::before,
.fi.fi-mailing-address::before {
  content: "\f022";
}
.fi.fi-move-left::before {
  content: "\f359";
}
.fi.fi-minus::before {
  content: "\f068";
}
.fi.fi-gear::before,
.fi.fi-cog::before,
.fi.fi-showProperties-route::before,
.fi.fi-service::before,
.fi.fi-services::before,
.fi.fi-serviceUser::before,
.fi.fi-serviceUsers::before {
  content: "\f013";
}
.fi.fi-shop::before {
  content: "\f07a";
}
.fi.fi-newsletter-segment-segmentData-fieldType-time::before,
.fi.fi-clock::before,
.fi.fi-clock::before,
.fi.fi-protocol::before {
  content: "\f017";
}
.fi.fi-password-input::before {
  content: "\f11c";
}
.fi.fi-collapse::before,
.fi.fi-caret-down::before {
  content: "\f0d7";
}
.fi.fi-download::before {
  content: "\f019";
}
.fi.fi-mobile::before {
  content: "\f3ce";
}
.fi.fi-newsletter-segment-segmentData-fieldType-text::before {
  content: "\f037";
}
.fi.fi-home::before,
.fi.fi-homeRoute::before {
  content: "\f015";
}
.fi.fi-upload::before {
  content: "\f093";
}
.fi.fi-validators::before,
.fi.fi-validator::before {
  content: "\f0e7";
}
.fi.fi-move-down::before {
  content: "\f358";
}
.fi.fi-cooperation::before,
.fi.fi-cooperations::before {
  content: "\f4c4";
}
.fi.fi-open-location::before {
  content: "\f3c5";
}
.fi.fi-mediaFile::before {
  content: "\f15b";
}
.fi.fi-arrow-down::before,
.fi.fi-arrow-down-stop::before,
.fi.fi-tagFamily-inherited::before {
  content: "\f063";
}
.fi.fi-link::before,
.fi.fi-location-options::before {
  content: "\f0c1";
}
.fi.fi-play::before {
  content: "\f04b";
}
.fi.fi-search::before {
  content: "\f002";
}
.fi.fi-shop-product::before {
  content: "\f218";
}
.fi.fi-arrow-up::before,
.fi.fi-arrow-up-stop::before {
  content: "\f062";
}
.fi.fi-activities-issue::before {
  content: "\f0ae";
}
.fi.fi-user-circle::before {
  content: "\f2bd";
}
.fi.fi-client::before,
.fi.fi-clients::before,
.fi.fi-showInsert-client::before {
  content: "\f2bd";
}
.fi.fi-shop-user::before {
  content: "\f505";
}
.fi.fi-copy::before,
.fi.fi-copy::before {
  content: "\f0c5";
}
.fi.fi-plus::before {
  content: "\2b";
}
.fi.fi-times::before,
.fi.fi-close-full-screen::before {
  content: "\f00d";
}
.fi.fi-map-o::before,
.fi.fi-layout::before {
  content: "\f279";
}
.fi.fi-activities-competition::before {
  content: "\f091";
}
.fi.fi-refresh::before {
  content: "\f2f1";
}
.fi.fi-robot::before {
  content: "\f544";
}
.fi.fi-gears::before,
.fi.fi-cogs::before,
.fi.fi-assignedService::before {
  content: "\f085";
}
.fi.fi-angles-left::before {
  content: "\f100";
}
.fi.fi-shield::before {
  content: "\f132";
}
.fi.fi-hashtag::before,
.fi.fi-tagScopes::before {
  content: "\23";
}
.fi.fi-infinity::before {
  content: "\f534";
}
.fi.fi-calendar-monthly::before,
.fi.fi-calendar-monthly::before {
  content: "\f133";
}
.fi.fi-plus-circle::before {
  content: "\f055";
}
.fi.fi-masterComponents::before {
  content: "\f24d";
}
.fi.fi-confirm-mobile::before,
.fi.fi-sms::before {
  content: "\f7cd";
}
.fi.fi-reports::before {
  content: "\f02d";
}
.fi.fi-user-plus::before {
  content: "\f234";
}
.fi.fi-check::before,
.fi.fi-decisionComponent-submit::before,
.fi.fi-decisionComponent-default::before,
.fi.fi-ok-confirm::before,
.fi.fi-decisionComponent-assign::before {
  content: "\f00c";
}
.fi.fi-angle-left::before {
  content: "\f104";
}
.fi.fi-livecom-jobs::before {
  content: "\f0b1";
}
.fi.fi-window-close::before {
  content: "\f410";
}
.fi.fi-exclamation-triangle::before {
  content: "\f071";
}
.fi.fi-database::before,
.fi.fi-segment::before {
  content: "\f1c0";
}
.fi.fi-resend::before {
  content: "\f064";
}
.fi.fi-send-confirmation::before {
  content: "\f1d8";
}
.fi.fi-calendar-daily::before,
.fi.fi-calendar-daily::before {
  content: "\f783";
}
.fi.fi-times-circle::before {
  content: "\f057";
}
.fi.fi-userSessions::before {
  content: "\f4fd";
}
.fi.fi-usd::before {
  content: "\24";
}
.fi.fi-scope,
.fi.fi-scopes {
  font-weight: 400;
}
.fi.fi-superpowers:before,
.fi.fi-scope:before,
.fi.fi-scopes:before {
  content: "\f2dd";
}
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-circle-o,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-tagFamily-unselected,
.fi.fi-tagFamily-selected,
.fi.fi-tag-unselected,
.fi.fi-tag-selected,
.fi.fi-category,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-placeholder,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-flag,
.fi.fi-layouts,
.fi.fi-component,
.fi.fi-form,
.fi.fi-layout,
.fi.fi-route,
.fi.fi-routeFallback,
.fi.fi-banner,
.fi.fi-clock,
.fi.fi-copy,
.fi.fi-check-square,
.fi.fi-envelope-o,
.fi.fi-user-o,
.fi.fi-scopeRole,
.fi.fi-scopeRoles,
.fi.fi-section,
.fi.fi-times-circle,
.fi.fi-minus-circle,
.fi.fi-file-text,
.fi.fi-mailinglist,
.fi.fi-address,
.fi.fi-addresses,
.fi.fi-protocol {
  font-weight: 400;
}
.fi.fi-trash,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-check-double,
.fi.fi-copy-to-clipboard,
.fi.fi-circle-question,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-info,
.fi.fi-tooltip,
.fi.fi-list,
.fi.fi-send-confirmation,
.fi.fi-confirm-mail,
.fi.fi-confirm-mobile,
.fi.fi-no-change,
.fi.fi-ban,
.fi.fi-password-input,
.fi.fi-play,
.fi.fi-pause,
.fi.fi-tel,
.fi.fi-mobile,
.fi.fi-email,
.fi.fi-open,
.fi.fi-open-location,
.fi.fi-heartbeat-connection,
.fi.fi-resetPassword,
.fi.fi-generatePasswordOfLength,
.fi.fi-link,
.fi.fi-categoryData,
.fi.fi-categoryData-default,
.fi.fi-setDefault-categoryData,
.fi.fi-user-circle,
.fi.fi-location-options,
.fi.fi-livecom-jobs,
.fi.fi-livecom-members,
.fi.fi-sms,
.fi.fi-participant,
.fi.fi-cut,
.fi.fi-paste,
.fi.fi-reset,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-open-routes,
.fi.fi-events,
.fi.fi-show-select-columns,
.fi.fi-envelope,
.fi.fi-user,
.fi.fi-user-plus,
.fi.fi-section-bulk,
.fi.fi-paperclip,
.fi.fi-search,
.fi.fi-globals,
.fi.fi-userSessions,
.fi.fi-subscriber,
.fi.fi-user-edit,
.fi.fi-segment,
.fi.fi-resend,
.fi.fi-address-type,
.fi.fi-address-type-client,
.fi.fi-address-type-journalist,
.fi.fi-address-type-media,
.fi.fi-address-type-contact,
.fi.fi-trip,
.fi.fi-cooperation,
.fi.fi-cooperations,
.fi.fi-address-book,
.fi.fi-mailingUrl-viewSubscribers,
.fi.fi-shop,
.fi.fi-shop-product,
.fi.fi-shop-order,
.fi.fi-shop-coupon,
.fi.fi-shop-user,
.fi.fi-euro,
.fi.fi-clock,
.fi.fi-reports,
.fi.fi-robot,
.fi.fi-protocol-protocol {
  font-weight: 900;
}
.fi.fi-cut {
  font-weight: 900 !important;
}
.fi.fi-paste {
  font-weight: 900 !important;
}
.fi.fi-reset {
  font-weight: 900 !important;
}
.fi.fi-showProperties-route {
  font-weight: 900 !important;
}
.fi.fi-routeTree {
  font-weight: 900 !important;
}
.fi.fi-layouts {
  font-weight: 400 !important;
}
.fi.fi-components {
  font-weight: 900 !important;
}
.fi.fi-atoms {
  font-weight: 900 !important;
}
.fi.fi-tagScopes {
  font-weight: 900 !important;
}
.fi.fi-validators {
  font-weight: 900 !important;
}
.fi.fi-validator {
  font-weight: 900 !important;
}
.fi.fi-banner {
  font-weight: 400 !important;
}
.fi.fi-art-download-stats {
  font-weight: 900 !important;
}
.fi.fi-news {
  font-weight: 900 !important;
}
.fi.fi-service {
  font-weight: 900 !important;
}
.fi.fi-services {
  font-weight: 900 !important;
}
.fi.fi-client {
  font-weight: 900 !important;
}
.fi.fi-clients {
  font-weight: 900 !important;
}
.fi.fi-showInsert-client {
  font-weight: 900 !important;
}
.fi.fi-user {
  font-weight: 900 !important;
}
.fi.fi-privilege {
  font-weight: 900 !important;
}
.fi.fi-privileges {
  font-weight: 900 !important;
}
.fi.fi-userSessions {
  font-weight: 900 !important;
}
.fi.fi-subscriber {
  font-weight: 900 !important;
}
.fi.fi-segment {
  font-weight: 900 !important;
}
.fi.fi-resend {
  font-weight: 900 !important;
}
.fi.fi-trip {
  font-weight: 900 !important;
}
.fi.fi-trips {
  font-weight: 900 !important;
}
.fi.fi-cooperation {
  font-weight: 900 !important;
}
.fi.fi-cooperations {
  font-weight: 900 !important;
}
.fi.fi-activity {
  font-weight: 900 !important;
}
.fi.fi-activities {
  font-weight: 900 !important;
}
.fi.fi-activities-newsletter {
  font-weight: 900 !important;
}
.fi.fi-activities-mail {
  font-weight: 400 !important;
}
.fi.fi-activities-competition {
  font-weight: 900 !important;
}
.fi.fi-activities-lobbying {
  font-weight: 900 !important;
}
.fi.fi-activities-issue {
  font-weight: 900 !important;
}
.fi.fi-mailing-address {
  font-weight: 400 !important;
}
.fi.fi-mailingUrl-viewSubscribers {
  font-weight: 900 !important;
}
.fi.fi-shop-product {
  font-weight: bold !important;
}
.fi.fi-shop-order {
  font-weight: bold !important;
}
.fi.fi-shop-coupon {
  font-weight: bold !important;
}
.fi.fi-shop-user {
  font-weight: bold !important;
}
.fi.fi-reports {
  font-weight: 900 !important;
}
.fi.fi-robot {
  font-weight: 900 !important;
}
.fi.fi-protocol {
  font-weight: 900 !important;
}
.fi.fi-protocol-protocol {
  font-weight: 900 !important;
}
.fi.fi-protocol-stats {
  font-weight: 900 !important;
}
