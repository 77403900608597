@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_edit_stats_controller {
	display: flex;
	flex-direction: column;
	height: 100%;

	& > section {

		&.top {
			height: 40%;
			min-height: 320px;
		}

		&.bottom {
			height: 60%;
		}
	}
}