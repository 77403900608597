@import "~baseVariables";
@import "~masterVariables";
.container_templates_mediaManagement_imageAssignmentDetails_imageAssignmentDetails .window {
  width: 80%;
  height: 60%;
  top: 10%;
  left: 10%;
}

.templates_mediaManagement_imageAssignmentDetails_imageAssignmentDetails {
  position: relative;
  width: 100%;
  height: 100%;

  & > section {
    outline: 1px solid #AAAAAA;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 15px;
  }

  section.left {
    position: relative;
    float: left;
    width: 35%;
    height: inherit;
  }

  section.center {
    position: relative;
    float: left;
    width: 50%;
    height: inherit;
    overflow-y: auto;
  }

  section.right {
    position: relative;
    float: left;
    width: 15%;
    height: inherit;
  }
}
