@import "~baseVariables";
@import "~masterVariables";
.templates_treesManagement_propertiesForm .base_form_elements_buttons {
	padding-bottom: 20px;
}
.base_tabStrip_tabContent > .templates_treesManagement_propertiesForm {
	padding: 1em;
	margin: 0;
	box-sizing: border-box;
}
