@import "~baseVariables";
@import "~masterVariables";
.templates_mediaManagement_upload_plupload_plupload {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: auto;

  button.upload {
    width: inherit;
    padding: 10px;
    line-height: 135%;
    appearance: unset;
    border: 1px solid #555555;
    border-radius: 3px;
    color: #555555;
    box-sizing: border-box;

    &.taggedUpload {
      margin-top: 20px;
    }

    &:hover {
      cursor: pointer;
      border-color: #222222;
      color: #222222;
    }
  }

  .progress {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 20px;
    margin: 20px 0 0 0;
    border-radius: 5px;
    border: 1px solid #333;
    overflow: hidden;
    opacity: 0.1;
    transition-duration: .3s;
    transition-property: opacity;
    transition-timing-function: ease;
    transition-delay: .1s;

    &.inProgress {
      opacity: 1;
    }

    .background {
      position: relative;
      width: 0;
      height: 100%;
      background-color: lightblue;
    }

    .content {
      position: relative;
      width: 100%;
      height: 100%;
      margin-top: -20px;
      padding: 3px;
      text-align: center;
      color: #333;
      font-size: 14px;
    }
  }

  .uploads {
    display: none;
  }
}