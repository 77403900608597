@import "~baseVariables";
@import "~masterVariables";
.templates_mediaManagement_mediaDetails_mediaInfoField,
.base_form_form .cols .col .field > .templates_mediaManagement_mediaDetails_mediaInfoField:not(.fieldIndependent) {
	width: 100% !important;
	float: unset;
	padding-bottom: .5em !important;
	margin-bottom: .5em !important;
	border-bottom: 1px solid #CCCCCC !important;

	& > .thumb {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		min-width: 110px;
		min-height: 110px;
		max-width: 110px;
		max-height: 110px;
		box-sizing: border-box;
		padding: 5px;
		margin: 0 .5em .5em 0;
		background-color: #faebd7;
		border: 1px solid #808080;

		& > img {
			margin: 0 auto;
			max-width: 100%;
			max-height: 100%;
		}
	}

	& > table {
		margin-bottom: .5em;
	}

	& > ul {
		display: none;
	}
}