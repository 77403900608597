@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_controller .templates_newsletter_mailings_simpleStatCharts {
	& > .legend {
		& > ul {
			font-size: 13px;
		}
	}
}

.templates_newsletter_mailings_edit_stats_stats .templates_newsletter_mailings_simpleStatCharts {
	margin-top: 0;
}

.templates_newsletter_mailings_simpleStatCharts {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-top: auto;

	& > .canvasContainer {
		width: 50%;
		padding: 1em;
	}

	& > .legend {
		width: 50%;
		padding-left: 1em;

		& > ul {
			list-style-type: none;
			font-size: 1em;
			margin: 0;
			padding: 0;

			& > li {
				display: inline-flex;
				flex-direction: row;
				justify-content: flex-start;
				margin-bottom: .5em;

				& > span {
					width: 2.5em;
					height: 1em;
					margin-right: .5em;
				}
			}
		}
	}
}