@import "~baseVariables";
@import "~masterVariables";
.templates_ttgShop_orders_history_history {
	display: flex;
	flex-direction: column;
	height: 100%;

	& > h1 {
		color: @primaryColor;
		text-transform: uppercase;
		font-size: 1.5em;
		margin: 0 10px 16px 0;
		font-weight: 400;
	}

	& > ol {
		margin: 0;
		list-style-type: none;
		padding-inline-start: 1em;
		max-height: 100%;
		overflow: auto;

		& > li {
			padding: .25em .6em;
			background-color: #333;
			color: #FFFFFF;
			border-radius: .25em;
			margin-bottom: .25em;

			&:last-child {
				background-color: @primaryColor;
			}
		}
	}
}