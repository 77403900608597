@import "~baseVariables";
@import "~masterVariables";
.templates_mediaManagement_artImageArchive_baseFormMediaImageAdditionalData {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	margin-top: auto;
	padding: 0 0 3px 0;
	box-sizing: border-box;

	& > table {
		tr {
			& > td {
				font-size: .875rem;

				&.title {
					font-weight: bold;
					padding-right: 10px;
				}
			}
		}
	}

	& > .right {
		font-size: 2rem;
	}
}