@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_edit_stats_charts_singleSemiDoughnut {
	//display: flex;
	//flex-direction: column;
	//justify-content: center;
	//align-items: center;
	//width: 100%;
	//width: 10vw;
	//height: 10vw;
	//padding: .5em;

	& * {
		//outline: 1px solid black;
	}

	//& > .ratio {
	//	flex: 1 0 auto;
	//	height: auto;
	//
	//	&:before {
	//		content: '';
	//		display: block;
	//		padding-top: 100%;
	//	}
	//
	//	& > .ratioContent {
	//		flex: 1 0 auto;
	//		height: auto;
	//		transform: translateY(-100%);
	//	}
	//}

	& > .base {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100%;
		//width: 10vw;
		//height: 10vw;

		//display: flex;
		//flex-direction: column;
		//justify-content: center;
		//width: 100%;
		//height: 100%;

		//&:before {
		//	content: '';
		//	display: block;
		//	padding-top: 100%;
		//}

		& > .title {
			font-size: 18px;
			//margin-top: 1em;
		}


		& > .center {
			font-size: 22px;
			font-weight: bold;
			margin-top: 2.75vw;
		}

		& > .footer {
			font-size: 14px;
			font-style: italic;
			padding-bottom: .75vw;
		}

		//& > .canvasContainer {
		//	//position: fixed;
		//	//transform: translateY(-100%);
		//	//display: block;
		//	//padding-top: 100%;
		//	//width: 100%;
		//	//height: 100%;
		//	//width: 50%;
		//	width: 100%;
		//	height: 100%;
		//	//padding: 1em;
		//
		//	//margin-top: -2em;
		//
		//	//& > .chartjs-render-monitor {
		//	//	max-width: 100%;
		//	//	max-height: 100%;
		//	//}
		//}
	}

	& > .overlay {
		//flex: 1 0 auto;
		width: 100%;
		height: 100%;
		transform: translateY(-100%);

		& > .canvasContainer {
			width: 100%;
			height: 100%;
		}

		//&:before {
		//	content: '';
		//	display: block;
		//	padding-top: 100%;
		//}

		//& > .container {
		//	position: fixed;
		//	max-width: 100%;
		//	max-height: 100%;

			//& > .title {
			//	font-size: 18px;
			//	//margin-top: 1em;
			//}
			//
			//
			//& > .center {
			//	font-size: 22px;
			//	font-weight: bold;
			//	margin-top: 2.75vw;
			//}
			//
			//& > .footer {
			//	font-size: 14px;
			//	font-style: italic;
			//	padding-bottom: .75vw;
			//}
		//}

	}


	//& > .legend {
	//	width: 50%;
	//	padding-left: 1em;
	//
	//	& > ul {
	//		list-style-type: none;
	//		font-size: 1em;
	//		margin: 0;
	//		padding: 0;
	//
	//		& > li {
	//			display: inline-flex;
	//			flex-direction: row;
	//			justify-content: flex-start;
	//			margin-bottom: .5em;
	//
	//			& > span {
	//				width: 2.5em;
	//				height: 1em;
	//				margin-right: .5em;
	//			}
	//		}
	//	}
	//}
}