@import "~baseVariables";
@import "~masterVariables";
@contactLabelWidth: 160px;

.base_form_form.templates_artForm_address_propertiesForm {

	&:not(.isHorizontal) {

		&.treatAsContact {

			.cols {

				.col {

					/* GROUPS AND FIELDS */
					& > .group,
					& > .field {

						& > *:not(.fieldIndependent) {
							width: ~"calc(100% - @{contactLabelWidth})" !important;
						}

						& > label:not(.fieldIndependent) {
							width: @contactLabelWidth !important;
						}
					}
				}
			}
		}

		.cols {

			.col {

				/* GROUPS */
				& > .group {

					&.type {

						& > .fieldOf_contactType {
							width: 50% !important;
						}
					}

					&.street {

						& > .fieldOf_street {
							width: 100% !important;
						}
						& > .fieldOf_streetNr {
							width: 15% !important;
						}
					}

					&.zipCityCountry {

						& > .fieldOf_zip {
							width: 50% !important;
						}

						& > .fieldOf_city {
							width: 100% !important;
						}

						& > .fieldOf_country {
							width: 100% !important;
						}
					}
				}

				/* FIELDS AND GROUP'S FIELDS */
				& > .group > .field,
				& > .field {

					&.fieldOf_media_circulation,
					&.fieldOf_media_pagePrice {
						& > input {
							text-align: left;
						}
					}

					& > [data-field-code="info"]:not(.fieldIndependent) {
						width: 100% !important;
						min-height: 242px;
						color: #AA0000;
					}

					& > .base_form_fields_tag-picker-button:not(.fieldIndependent) {
						width: 100% !important;

						& > section.top > button {
							margin: 0;
						}
					}
				}
			}
		}

		&.treatAsContact {

			.cols {

				.col {
					width: 33%;
					box-sizing: border-box;
					padding-left: 1em;
					padding-top: ~"calc(1em + 42px)";

					&:nth-child(1){
						width: 50%;
						padding-left: 0;
						padding-top: 0;
					}
					&:nth-child(2){
						width: 50%;
						padding-top: 0;
					}
					&:nth-child(3){
						width: 34%;
						padding-left: 0;
					}
					&:nth-child(4){
						padding-top: 1em;
					}

					& > .group > .field,
					& > .field {
						& > [data-field-code="info"]:not(.fieldIndependent) {
							min-height: 284px;
						}
					}
				}
			}
		}

		&:not(.treatAsContact){
			.cols {
				height: auto;
				min-height: 100%;

				.col {
					padding: 1em;
					height: auto;
					min-height: 100%;

					&:first-child {
						border-right: 1px solid #CDCDCD;
					}
				}
			}
		}
	}
}

.container_base_form_form.container_templates_artForm_address_propertiesForm .window {
	width: 70%;
	height: 85%;
	top: 5%;
	left: 15%;
}