@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_treesManagement_filter {
  & > .cols > .col > .field > .base_form_fields_tag-picker-button {
    width: 100% !important;
  }


  &.isHorizontal {
    width: 35%;
    box-sizing: border-box;
    padding: 8px 0 0 20px;

    label {
      display: none !important;
    }

    .cols {

      .col {
        display: flex;
        justify-content: flex-start;
        padding: 0;

        .base_form_elements_field {
          margin-bottom: 0;

          &.field > *:not(.fieldIndependent) {
            width: 100% !important;
          }
        }

        .buttons {
          width: auto;
          margin: 0 8px 0 0;

          button {
            float: unset;
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            margin-left: 0;

            i {
              margin-right: 6px;
            }
          }
        }

        & > .base_form_elements_field.fieldOf_type {
          margin-left: 8px;
        }
      }
    }
  }
}

.templates_mblx_products_controller.usageMode_choose .base_form_form.templates_treesManagement_filter,
.base_trees_trees.splitScreen .base_form_form.templates_treesManagement_filter {
  width: 100%;
  padding-right: 20px;
}
