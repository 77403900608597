@import "~baseVariables";
@import "~masterVariables";
.templates_mediaManagement_imageAssignmentDetails_thumb {
  position: relative;
  float: left;
  width: 110px;
  height: 110px;
  padding: 5px;
  margin: 12px 0 0 12px;
  outline: 1px solid gray;
  box-sizing: border-box;

  img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  &.selected {
    background-color: #A52A2A;
  }

  &:hover {
    cursor: pointer;
    background-color: #bf2b2b;
  }
}