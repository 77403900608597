@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_propertiesForms_routeDuplicateOptions {
	& > .cols {
		display: flex;

		& > .col {
			width: unset;

			section.descriptions {
				margin-left: 20px;
				width: 200px;
			}
		}
	}
}

.base_modal_modal.container_base_form_form.container_templates_propertiesForms_routeDuplicateOptions {
	& > .window {
		width: 80%;
		height: 60%;
		top: 15%;
		max-width: 1100px;
	}
}