@import "~baseVariables";
@import "~masterVariables";
.base_modal_modal.container_templates_ttgShop_coupons_controller {

	& > .window {
		width: calc(100% - 240px);
		left: 120px;
		top: 35px;
	}
}


.templates_ttgShop_coupons_controller {
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	width: 100%;
	height: 100%;

	& > section {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;

		&.selection {
			display: none;
			width: 30%;
		}

		& > section {
			display: flex;
			flex-direction: column;
			width: 100%;
			box-sizing: border-box;

			&.top {
				flex-direction: row;
				justify-content: space-between;
				border-bottom: 1px solid #CCCCCC;
				min-height: 50px;

				& > section {
					display: flex;
					flex-direction: row;
					width: 100%;

					& > h1 {
						display: inline-flex;
						color: @primaryColor;
						text-transform: uppercase;
						font-size: 1.5em;
						align-self: center;
						margin: 10px 10px 10px 1rem;

					}

					&.left {
						justify-content: flex-start;
						width: 30%;
					}
					&.center {
						justify-content: center;
						width: 30%;
					}
					&.right {
						justify-content: flex-end;
					}

					& > button {
						margin: 10px 10px 10px 0;
						padding: 8px 12px;
						cursor: pointer;
						background-color: #eeeeee;
						border: 1px solid #999;
						border-radius: 3px;

						&:hover, &:focus {
							background-color: #FFFFFF;
						}

						&:focus {
							outline: none;
						}

						i {
							margin-right: 6px;
							width: 16px;
							font-size: 1rem;
						}

						&.positive {
							color: #FFFFFF;
							background-color: #006600;
							border-color: #006600;
							&:hover, &:focus {
								background-color: #004F00;
							}
						}
						&.negative {
							color: #FFFFFF;
							background-color: #B20000;
							border-color: #B20000;
							&:hover, &:focus {
								background-color: #880000;
							}
						}
					}
				}
			}

			&.bottom {
				flex-direction: row;
				height: 0;
				flex: 1 1 auto;

				& > section {
					display: flex;
					flex-direction: column;
					height: 100%;

					&.filter {
						display: flex;
						flex-direction: column;
						width: 400px;
						padding: 1em;
						float: none;
						border-right: 1px solid #DDDDDD;
						box-sizing: border-box;
					}
					&.list {
						display: flex;
						flex-direction: column;
						width: ~"calc(100% - 400px)";
						height: 100%;
						float: none;
					}
					&.selection {
						display: none;
					}
				}
			}
		}
	}

	&.usage_selection {

		& > section {

			& > section > section.bottom {

				& > section {

					&.list {
						width: ~"calc(70% - 400px)";
					}

				}
			}

			&.selection {
				display: flex;
				flex-direction: column;
				width: 30%;
				height: 100%;
				float: none;
				border-left: 1px solid #CCCCCC;
			}
		}
	}
}