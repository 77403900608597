@import "~baseVariables";
@import "~masterVariables";
.templates_newsletter_mailings_edit_controller {
	display: flex;
	flex: 1 1 auto;
	height: 100%;

	section.content {
		display: flex;
		flex: 1 1 auto;
	}

	// hide filter of component edit tree
	.base_trees_trees section.filter {
		display: none;
	}
}