@import "~baseVariables";
@import "~masterVariables";
.templates_protocol_stats_chart {
  display: flex;
  flex-direction: row;
  padding: 1em;

  & > * {
    padding: 1em;

    &.canvasContainer {
      width: 100%;
    }
    &.description {
      width: 40%;
    }
  }
}
