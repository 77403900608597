@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_mblx_reports_generator {
  padding: 2em;

  & > .cols {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;

    & > .col {
      width: 60%;
      margin-right: 2%;

      & > section {
        &.uploader {
          margin-bottom: 10px;
        }
      }

      &:nth-child(2) {
        width: 38%;
        margin-right: 0;
      }
      &:nth-child(3) {
        width: 60%;
      }
    }
  }
}