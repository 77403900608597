@import "~baseVariables";
@import "~masterVariables";
.templates_routeContentTrees_headerActions {
	float: right;
	margin-top: 24.5px;
	margin-right: 1em;

	button {

		&,
		&:active,
		&:focus{
			--fec-button-color: #FFFFFF;
			background-color: #572C00;
			border-color: transparent;
		}

		&:hover {
			background-color: #9c5000 !important;
			border-color: #572C00 !important;
		}
	}
}