@import "~baseVariables";
@import "~masterVariables";
.templates_routeTrees_subActions {

  button {

    &.insertLang {
      background-color: saddlebrown;
      color: white;
      font-weight: bold;
      font-size: 2em;
      content: "+"
    }
  }
}