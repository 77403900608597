@import "~baseVariables";
@import "~masterVariables";
@labelWidth: 125px;

.base_form_form.templates_artForm_entity_propertiesForm {



	&:not(.isHorizontal){

		& > .cols {

			& > .col {

				/* GROUPS AND FIELDS */
				& > .group,
				& > .field {

					& > *:not(.fieldIndependent) {
						width: ~"calc(100% - @{labelWidth})" !important;
					}

					& > label:not(.fieldIndependent) {
						width: @labelWidth !important;
						flex: 1 0 auto;
					}
				}
			}
		}

		& > .cols {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 100%;

			& > .col {
				width: 70%;
				box-sizing: border-box;

				&:first-child {
					width: 30%;
					padding-right: 1em;
				}

				/* GROUPS */
				& > .group {
					display: flex;
					flex-direction: row;

					& > .field {

						& > *:not(.fieldIndependent) {
							width: 100% !important;
						}
					}

					& > label:not(.fieldIndependent) {
						transform: unset;
						align-self: center;
						margin-bottom: 10px;
					}
				}

				/* FIELDS AND GROUP'S FIELDS */
				& > .group > .field,
				& > .field {
					& > select:not(.fieldIndependent) {
						padding: 5px 7px;
						box-sizing: border-box;
						height: 2rem;
					}

					& > .base_form_fields_toggle {
						--toggle-horizontal-padding: 12px;
					}
				}
			}
		}
	}
}