@import "~baseVariables";
@import "~masterVariables";
.base_modal_modal.container_templates_ttgShop_orders_propertiesForm {
	& > .window {
		width: 90%;
		height: 94%;
		top: 3%;
		left: 5%;

		& > section.body {
			& > .base_form_form {
				padding: 0 10px;
			}
		}
	}
}

.base_form_form.templates_ttgShop_orders_propertiesForm {
	& > .cols {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		height: 100%;

		& > .col {
			padding: 20px 10px;

			&:nth-child(1){
				display: flex;
				flex-direction: column;
				width: 80%;

				& > section.leftBottom {
					min-height: 60px;
					margin-top: auto;
				}

				& > *.disabled > label {
					color: #000000;
				}
			}
			&:nth-child(2){
				display: flex;
				flex-direction: column;
				width: 100%;

				& > * {
					width: auto;
					align-self: flex-start;
				}

				& > .group {
					width: 100%;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;

					&.addresses {
						margin-bottom: 1em;
					}

					&.basket {
						flex: auto;
						overflow: hidden;
					}

					& > * {
						width: 100% !important;

						//& > label {
						//	color: #000000;
						//}
					}
				}
			}
			&:nth-child(3){
				width: 40%;
			}

			& > h1 {
				display: inline-flex;
				color: @primaryColor;
				text-transform: uppercase;
				font-size: 1.5em;
				align-self: center;
				margin: 0 10px 16px 0;
				font-weight: 400;
			}

			.base_form_fields_text.textfield-integer,
			.base_form_fields_text.textfield-float {
				float: left;
				width: 10em !important;
			}

			.base_form_elements_field.fieldOf_address,
			.base_form_elements_field.fieldOf_shippingAddress {
				display: flex;
				flex-direction: column;

				& > label {
					font-weight: bold;
					transform: unset;
				}
			}

			& > section {
				.base_form_form.isHorizontal > .cols > .col > *.group > *.field,
				.base_form_relationship.inline > .content > .base_form_form.isHorizontal > .cols > .col > *.field {
					.base_form_fields_text {
							width: 100px !important;
					}
					.base_form_fields_air-datetime-picker {
						& > input {
							width: 110px;
						}
					}
					&.fieldOf_minNrOfElements > .base_form_fields_text {
						width: 150px !important;
					}
				}

				&.right {
					display: flex;
					flex-direction: column;
					height: 100%;

					& > .base_form_fieldDescription {
						margin-top: auto;
					}
				}
			}

			.base_tabStrip_tabStrip {
				height: 100%;

				& > section.tabs {
					border: 1px solid #CDCDCD;
					border-width: 0 1px;
					padding: 0;

					& > .base_tabStrip_tab {
						margin: 0 !important;
						border-right: 1px solid #CDCDCD;
						border-bottom: 11px solid transparent;
						border-radius: 0 !important;

						&.active {
							border-bottom: 11px solid @primaryColor;
						}
					}
				}

				& > section.tabContents {

					& > .base_tabStrip_tabContent {
						overflow: auto;
						margin-bottom: 0;
						border-bottom: 0;
					}
				}
			}
		}
	}
}