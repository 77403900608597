@import "~baseVariables";
@import "~masterVariables";
.templates_validatorManagement_validatorManagement {
  height: inherit;

  section {
    &.filter {
      width: 100%;
      height: 5px;
      color: #000;
      overflow: hidden;
      display: flex;
      background-color: #F9F9F9;
      border-bottom: 1px solid #CCCCCC;
    }

    &.tables {
      width: 100%;
      height: inherit;
      padding: 2vh;
      box-sizing: border-box;
    }
  }
}