@import "~baseVariables";
@import "~masterVariables";
.base_form_form .cols.templates_newsletter_segments_data_list,
.templates_newsletter_segments_data_list {

	.col {

		.field {

			.segmentDataList {
				display: flex;
				flex-direction: column;
				width: 100% !important;

				h2 {
					font-size: 1.2em;
				}

				.list {
					width: 100%;
				}

				.add-control {
					width: 100%;
					text-align: center;

					button {
						float: none;


					}
				}
			}
		}
	}
}

