@import "~baseVariables";
@import "~masterVariables";
.base_form_form.templates_propertiesForms_eventPropertiesForm {
  & > .cols:first-child {
    max-width: 42.5em;
  }

  .fieldOf_maxNrOfParticipants {

    & > .base_tooltip_tooltip {
      float: left;
    }

    & > .base_form_fields_text {
      float: left !important;
      max-width: 5em;
    }
  }

  .base_form_form[data-sub-form-alias="registrationOptions"] {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 -.5em;

    & > .cols .col .field {
      margin: .5em 0 0 0;

      & > *:not(.fieldIndependent) {
        width: 20% !important;
      }
      & > label:not(.fieldIndependent) {
        width: 80% !important;
      }
    }

    & > .cols {
      max-width: 25em;
    }

    & > .cols,
    & > .base_form_form {
      flex: 1 1 100%;
      margin: 0 0.5em;
      border: 1px solid #A7A6A6;
      display: flex;
      background-color: #e1e1e1;
      padding: 1em;

      h1 {
        text-align: center;
        font-size: 1.25rem;
        margin: -1rem -1rem .5rem -1rem;
        display: block;
        float: unset;
        padding: 1rem;
        border-bottom: 1px solid #A7A6A6;
      }

      &,
      .base_form_form {
        & > .cols .col .field > *:not(.fieldIndependent) {
          width: 70% !important;
        }
        & > .cols .col .field > label:not(.fieldIndependent) {
          width: 30% !important;
        }

        & > .content {
          width: 100%;
          padding: 0;
          margin: 0;
          border: none;
        }
      }
    }
  }
}

.base_tabStrip_tabContent > .templates_propertiesForms_eventPropertiesForm {
  padding: 2em;
}

.base_modal_modal.container_base_form_form.container_templates_propertiesForms_eventPropertiesForm {
  & > .window {
    width: 96%;
    height: 80%;
    left: 2%;
    top: 10%;
  }
}